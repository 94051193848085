import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  setRef,
  Card,
  LinearProgress,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../Assets/css/custom-class.css";
import validationRegExp from "../Configuration/ValidationConfig";
import PropTypes from "prop-types";
import Controller from "../Controller/ApiController";
import UtilDateTime from "../Constant/utillDateTime";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import "../Popup/bookappointment.css";
import dayjs from "dayjs";
import CancelAppointmentChild from "./CancelAppointmentChild";
import cancellIcon from "../Assets/images/DeletePopup/cancelIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import {
  BasicMenuItem,
  BasicSelect,
} from "../Components/ReuseComponents/Select/BasicSelect";
import { OutlinedInputField, OutlinedTextField } from "../Components/ReuseComponents/TextField/BasicTextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

export default function BookAppointment({
  open,
  onClose,
  type,
  data,
  setAppointmentAddUpdate,
  updateCount,
  openModalCancel,
  closeModalCancel,
}) {

  const [patientNameError, setPatientNameError] = useState("");
  const [selectSlotError, setSelectSlotError] = useState("");
  const [lineProgressLoading, setLineProgressLoading] = useState(false);
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [radioDisabled, setRadioDisabled] = React.useState(true);
  const [mobileError, setMobileError] = useState("");
  const mobileFieldFocus = useRef(null);
  const nameFieldFocus = useRef(null);
  const mobileTypeFocus = useRef(null);
  const [slotTimeFromTo, setSlotTimeFromTo] = useState([]);
  const [message, setMessage] = useState("");
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [users, setUsers] = useState([]);
  const [referredDoctor, setRefferedDoctor] = useState([]);
  const [editAppointmentUserType, setEditppointmentUSerType] = useState("");
  const doctorUID = localStorage.getItem("DoctorUid");
  var accessPermissonValueGet = useSelector(
    (state) => state.accessPermissionValue.access_values
  );
  const manageApiRef = useSelector(
    (state) => state.manageAppointmentSlice.appointment
  );
  const userDetails = useSelector((state) => state?.userDetails?.data || {});

  const Location = useLocation();
  const navigate = useNavigate();
  var initialStateErrors = {
    patientName: { required: false },
    mobileNumber: { required: false },
  };

  const [loaderopen, setloaderOpen] = React.useState(false);
  const storedDate = useSelector((state) => state.dateFilter.date);
  const [appointmentUserType, setAppointmentUserType] = useState("");
  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const [errors, setErrors] = useState(initialStateErrors);
  // var newdate = ;
  const [defaultDoctoUID, setDefaultDoctorUID] = useState("");
  const intialFormData = {
    consultation_type: {
      value: "NORMAL",
      error: false,
    },
    appointment_type: {
      value: "SINGLE",
      error: false,
    },
    appointment_date: {
      value: dayjs(new Date()),
      error: false,
    },
    edit_appointment_date: {
      value: "",
      error: false,
    },
    slot: {
      value: "",
      error: false,
    },
    patient_name: {
      value: "",
      error: false,
    },
    mobile_number: {
      value: "",
      error: false,
    },
    smart_phone: {
      value: false,
      error: false,
    },
    payment_mode: {
      value: "",
      error: false,
    },
    user_uid: {
      value: "",
      error: false,
    },
    booking_source: {
      value: "WEB_API",
      error: false,
    },
    visiting_status: {
      value: "NEW",
      error: false,
    },
    repeat_every_time: {
      value: "",
      error: false,
    },
    repeat_every_period: {
      value: "DAYS",
      error: false,
    },
    occurance: {
      value: "",
      error: false,
    },
    scan_type: {
      value: "",
      error: false,
    },
    referred_doctor: {
      value: "",
      error: false,
    },
  };

  const [formData, setFormData] = useState(intialFormData);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [scanTypes, setScanTypes] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    // Attach resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);  

  const cancelModel = () => {
    setOpenCancelModel(true);
  };

  const cancelModelclose = () => {
    setOpenCancelModel(false);
    onClose();
  };

  const cancelModelcloseChild = () => {
    setOpenCancelModel(false);
  };

  let currentdate = new Date();
  currentdate = currentdate.setHours(0, 0, 0, 0);

  const userRequest = async () => {
    try {
      let queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": doctorUID,
        "filter.user_uid": userDetails?.user_uid || "",
      };

      if(userDetails.user_type != "DOCTOR" && userDetails.user_type != "SCAN") {
        queryParams = { ...queryParams, "filter.user_uid_type": "MAPED_DOCTOR" }
      }

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      if (response.type == "success") {
        const usersData = response?.data;
        if(Array.isArray(usersData)) {
          setUsers(usersData || []);
          if(usersData.length == 1) {
            const filterUser = response.data[0];
            setFormData((prevData) => {
              return { ...prevData, user_uid: {
                value: filterUser.user_uid,
                error: false
              } }
            })
            setAppointmentUserType(filterUser.user_type == "SCAN" ? "SCAN" : "DOCTOR");
          }
          setRefferedDoctor(usersData.filter((val) => val.user_type == "DOCTOR").map((val) => ({ full_name: val.full_name, custom: false })));
        }
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  const referredUserRequest = async () => {
    try {
      let queryParams = {
        "filter.user_type": "DOCTOR",
        "filter.doctor_uid": doctorUID,
      };

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      if (response.type == "success") {
        const usersData = response?.data;
        if(Array.isArray(usersData)) {
          setRefferedDoctor(usersData.map((val) => ({ full_name: val.full_name, custom: false })));
        }
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    userRequest();
    referredUserRequest();
  }, []);

  const getSlotTime = async () => {
    if (formData.user_uid.value == "") {
      setFormData((prevData) => ({
        ...prevData,
        user_uid: {
          value: prevData.user_uid.value,
          error: true,
        },
      }));
      return;
    }
    // handleLoaderOpen();
    let docteruid = formData.user_uid.value;

    let reqParams = {};
    if (appointmentUserType == "DOCTOR") {
      let slotStartTime = dayjs(new Date()).toISOString();

      if (
        dayjs(new Date()).startOf("day").toISOString() !=
        dayjs(formData.appointment_date.value).startOf("day").toISOString()
      ) {
        slotStartTime = dayjs(formData.appointment_date.value)
          .startOf("day")
          .toISOString();
      }

      reqParams = {
        "filter.slot_start_time": slotStartTime,
        "filter.paginate": "NO",
        "filter.consultation_type": formData.consultation_type.value,
        "filter.current_date":
          dayjs(formData.appointment_date.value).startOf("day").toISOString() ||
          "",        
          ...(formData.consultation_type.value !== "EMERGENCY" && {
            "filter.slot_status": "OPEN",
          }),
      };
    } else {
      reqParams = {
        "filter.current_date":
          dayjs(formData.appointment_date.value).startOf("day").toISOString() ||
          "",
      };
    }

    try {
      setLineProgressLoading(true);
      let res = await Controller.ApiController(
        "GET",
        `/get_slot/${docteruid}`,
        "?",
        reqParams
      );
      setLineProgressLoading(false);
      // handleLoaderClose();

      if (res.type == "success") {
        if (res.data && Array.isArray(res.data)) {
          let slotTimeListdata = [];
          if (appointmentUserType == "DOCTOR") {
            slotTimeListdata = res.data.map((item) => ({
              slot_uid: item.slot_uid,
              start_time: item.slot_start_time,
              end_time: item.slot_end_time,
            }));
          } else {
            slotTimeListdata = res?.data;
          }

          // if(formData.consultation_type == "EMERGENCY" && type != "editUser") {
          // setFormData({
          //   ...formData,
          //   slot_uid: slotTimeListdata[0].slot_uid,
          // });
          // }
          setSlotTimeFromTo(slotTimeListdata || []);
          if (message) {
            setMessage("");
          }
        } else if (res.message) {
          setMessage("No slots available");
          setLineProgressLoading(false);
          setSlotTimeFromTo([]);
        }
      } else {
        setMessage(res.error.message);
        setLineProgressLoading(false);
        setSlotTimeFromTo([]);
      }
    } catch (error) {
      // handleLoaderClose();
      setLineProgressLoading(false);
      console.log(error, "slotTimedata");
    }
  };
  
  const getScanType = async (value) => {
    if(appointmentUserType != "SCAN") {
      return;
    }

    let reqParams = {scan_duration: value};
    handleLoaderOpen();
    try {
      let res = await Controller.ApiController(
        "GET",
        `/scan_type`,
        "?",
        reqParams
      );
      handleLoaderClose();

      if (res.type == "success") {
        if (res.data && Array.isArray(res.data)) {
          let scanTypeList = res.data;
          scanTypeList = scanTypeList.map((val) => val?.scan_type_name || "").filter((val) => val != "")
          setScanTypes(scanTypeList || []);
        } else if (res.message) {
          setScanTypes([]);
        }
      } else {
        setScanTypes([]);
      }
    } catch (error) {
      handleLoaderClose();
      console.log(error, "scan types");
    }
  };
  
  const scanTypeConvert = (source) => {
    if(!source) {
      return "";
    }
    let value = source.split(" ");
    value = value.map((val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()).join(" ");
    return value;
  }

  useEffect(() => {
    if (type != "editUser") {
      let updateFormData = formData;
      if (formData.consultation_type.value == "EMERGENCY") {
        getSlotTime();
        setSelectSlotError("");
      } else {
        updateFormData.slot = {
          value: data?.slot.slot_uid ? data?.slot.slot_uid : "",
          error: false,
        };
      }
      if (
        formData.consultation_type.value == "NORMAL" ||
        formData.consultation_type.value == "EMERGENCY"
      ) {
        updateFormData.appointment_date.value = dayjs(new Date()).toISOString();
      } else if (formData.consultation_type.value == "REVIEW_APPOINTMENT") {
        updateFormData.appointment_date.value = dayjs(new Date())
          .add(1, "days")
          .toISOString();
      }

      if (
        userDetails.user_uid &&
        (userDetails.user_type == "DOCTOR" || userDetails.user_type == "SCAN")
      ) {
        // || userDetails.user_type == "SCAN")) {
        setDefaultDoctorUID(userDetails.user_uid);
        setAppointmentUserType(
          userDetails.user_type == "SCAN" ? "SCAN" : "DOCTOR"
        );
        updateFormData = {
          ...updateFormData,
          user_uid: {
            value: userDetails.user_uid,
            error: false,
          },
        };
      } else if (defaultDoctoUID) {
        setDefaultDoctorUID(defaultDoctoUID);
      }

      updateFormData.user_uid.error = "";
      setFormData({ ...updateFormData });
    }
  }, [formData.consultation_type.value, userDetails]);

  useEffect(() => {
    if (data) {
      let updateData = {
        consultation_type: {
          value: data.consultation_type,
          error: false,
        },
        appointment_type: {
          value: data.appointment_type,
          error: false,
        },
        appointment_date: {
          value: data.appointment_date,
          error: false,
        },
        edit_appointment_date: {
          value: data.appointment_date,
          error: false,
        },
        patient_name: {
          value: data.patient_name,
          error: false,
        },
        mobile_number: {
          value: data.mobile_number,
          error: false,
        },
        smart_phone: {
          value: data.smart_phone,
          error: false,
        },
        payment_mode: {
          value: data.payment_mode,
          error: false,
        },
        user_uid: {
          value: data.manage_user.user_uid,
          error: false,
        },
        booking_source: {
          value: data.booking_source,
          error: false,
        },
        visiting_status: {
          value: data.visiting_status,
          error: false,
        },
        repeat_every_time: {
          value: "",
          error: false,
        },
        repeat_every_period: {
          value: "DAYS",
          error: false,
        },
        occurance: {
          value: "",
          error: false,
        },
        scan_type: {
          value: data.scan_type,
          error: false,
        },
        referred_doctor: {
          value: data.referred_doctor,
          error: false,
        },
      };

      if (data?.manage_user?.user_type == "DOCTOR" && data.slot) {
        const slotTimeListdata = {
          slot_uid: data.slot.slot_uid,
          start_time: data.slot.slot_start_time,
          end_time: data.slot.slot_end_time,
        };
        updateData = {
          ...updateData,
          slot: {
            value: data?.slot?.slot_uid || "",
            error: false,
          },
        };
        setSlotTimeFromTo([slotTimeListdata] || []);
      } else {
        updateData = {
          ...updateData,
          slot: {
            value: data?.scan_slot_duration || "",
            error: false,
          },
        };
        let duration = data?.scan_slot_duration || "";

        setSlotTimeFromTo([duration] || []);
      }

      if (data.manage_user.user_type == "DOCTOR") {
        setAppointmentUserType("DOCTOR");
        setEditppointmentUSerType("DOCTOR");
      } else {
        setEditppointmentUSerType("SCAN");
        setAppointmentUserType("SCAN");
      }

      setFormData((prevData) => ({
        ...prevData,
        ...updateData,
      }));
      setUsers((prevData) => [...prevData, data.manage_user]);

      // if(formData.consultation_type == "EMERGENCY" && type != "editUser") {
      // setFormData({
      //   ...formData,
      //   slot_uid: slotTimeListdata[0].slot_uid,
      // });
      // }
    }
  }, [data]);

  // Initial state error validation

  useEffect(() => {
    if (formData.patient_name.value != "" && formData.patient_name.error) {
      setErrors({ ...errors, patientName: { required: false } });
    } else if (errors.patientName.required) {
      setErrors({ ...errors, patientName: { required: true } });
    }
  }, [formData.patient_name.value]);

  useEffect(() => {
    if (formData.mobile_number.value != "" && formData.mobile_number.error) {
      setErrors({ ...errors, mobileNumber: { required: false } });
    } else if (formData.mobile_number.error) {
      setErrors({ ...errors, mobileNumber: { required: true } });
    }
  }, [formData.mobile_number.value]);

  const clearInputFields = () => {
    formData.consultation_type = "NORMAL";
    formData.appointment_type = "";
    formData.appointment_date = "";
    formData.edit_appointment_date = "";
    formData.slot = "";
    formData.patient_name = "";
    formData.mobile_number = "";
    formData.smart_phone = true;
    formData.payment_mode = "";
    formData.visiting_status = "";
    formData.referred_doctor = "";
    formData.scan_type = "";
  };

  const ObjectKeyValueToValue = (object) => {
    let parseObject = {};
    const keys = Object.keys(object);
    keys.map((val) => {
      parseObject = { ...parseObject, [val]: object[val].value };
    });
    return parseObject;
  };

  const ObjectValueToKeyValue = (object) => {
    let parseObject = {};
    const keys = Object.keys(object);
    keys.map((val) => {
      parseObject = {
        ...parseObject,
        [val]: {
          value: object[val],
          error: false,
        },
      };
    });
    return parseObject;
  };

  const validateFields = () => {
    let patientNameValidate = false;
    let mobileValidate = false;
    let validate = true;
    let formDataError = formData;

    let mandatoryFields = [
      "user_uid",
      "patient_name",
      "appointment_date",
      "slot",
      "mobile_number",
    ];

    if (
      formData.consultation_type.value == "REVIEW_APPOINTMENT" &&
      formData.appointment_type.value == "MULTIPLE"
    ) {
      mandatoryFields = [
        ...mandatoryFields,
        "repeat_every_time",
        "repeat_every_period",
        "occurance",
      ];
    }

    mandatoryFields.map((val) => {
      if (formData[val] && !formData[val].value) {
        formDataError[val].error = "Enter the value";
        validate = false;
      }
    });

    if (formData.patient_name.value) {
      let validInput = false;
      if (type == "editUser") {
        validInput = validationRegExp.fullNameRegEdit(
          formData.patient_name.value
        );
      } else {
        validInput = validationRegExp.fullName(formData.patient_name.value);
      }
      if (!validInput) {
        formDataError.patient_name.error = "Invalid patient name";
      } else {
        formDataError.patient_name.error = "";
        patientNameValidate = true;
      }
    }
    if (formData.mobile_number.value) {
      let validationCheck = false;
      if (type == "editUser") {
        validationCheck = validationRegExp.mobileNumberEditReg.test(
          formData.mobile_number.value
        );
      } else {
        validationCheck = validationRegExp.mobileNumberReg.test(
          formData.mobile_number.value
        );
      }
      if (validationCheck) {
        let mobile = "";
        let firstIndex =
          formData.mobile_number.value[0] == undefined
            ? ""
            : formData.mobile_number.value[0];
        var repeat = true;
        for (var i = 0; i < formData.mobile_number.value.length; i++) {
          if (firstIndex != formData.mobile_number.value[i]) {
            repeat = false;
          }
        }
        for (let i = 0; i < formData.mobile_number.value.length; i++) {
          mobile +=
            formData.mobile_number.value[i] != " "
              ? formData.mobile_number.value[i]
              : "";
        }
        if (mobile.length > 9 || mobile == "") {
          if (repeat && mobile != "") {
            setMobileError("Invalid number");
            formDataError.mobile_number.error = "Invalid number";
          } else {
            setMobileError("");
            formDataError.mobile_number.error = "";
            mobileValidate = true;
          }
        } else {
          formDataError.mobile_number.error = "Mobile number must be 10 digit";
          setMobileError("Mobile number must be 10 digit");
        }
      }
    }

    setFormData({ ...formDataError });
    return patientNameValidate && mobileValidate && validate;
  };

  const appointmentData = useSelector(
    (state) => state.appointmentSlice.appointment
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    let focusField = true;

    const validation = validateFields();

    if (!validation) {
      return;
    }
    handleLoaderOpen();
    if (type == "editUser") {
      let deleteKeys = [
        "repeat_every_time",
        "repeat_every_period",
        "occurance",
      ];
      let datas = ObjectKeyValueToValue(formData);

      if (appointmentUserType == "DOCTOR") {
        datas = { ...datas, slot_uid: datas.slot };
        if (data?.slot?.slot_uid == datas?.slot_uid) delete datas.slot_uid;
      } else {
        datas = { ...datas, slot_duration: formData.slot.value };
      }
      delete datas.edit_appointment_date;
      delete datas.slot;
      // alert(JSON.stringify(datas));
      // alert(data?.appointment_uid);
      let res = await Controller.ApiController(
        "PUT",
        `/appointment/${data?.appointment_uid}`,
        "",
        datas
      );

      if (res) {
        if (res.type === "success") {
          handleLoaderClose();
          setDisabledSubmit(true);
          clearInputFields();
          onClose();
          updateCount((prev) => prev + 1); // setAppointmentAddUpdate(prev => alert(prev));
          Alert("success", "Appointment updated successfully");
        } else if(res?.status == "FAILED") {
          handleLoaderClose();
          Alert("error", res?.error?.message || "Something went wrong");
        } else {
          handleLoaderClose();
          Alert("error", "Something went wrong");
        }
      }
    } else {
      handleLoaderOpen();
      let data = ObjectKeyValueToValue(formData);
      if (appointmentUserType == "DOCTOR") {
        data = { ...data, slot_uid: data.slot };
      } else {
        data = { ...data, slot_duration: data.slot };
      }
      delete data.slot;
      delete data.edit_appointment_date;
      let res = await Controller.ApiController(
        "POST",
        "/appointment",
        "",
        data
      );

      if (res) {
        if (res.type === "success") {
          handleLoaderClose();
          setDisabledSubmit(true);
          Alert("success", "Appointment booked successfully");
          onClose();
          // setTimeout(() => {
          // setRefGetDataListing(refGetDataListing)

          clearInputFields();
          if (
            accessPermissonValueGet.includes("MANAGE_APPOINTMENTS") &&
            Location.pathname == "/manageappointments"
          ) {
            manageApiRef();
          } else if (Location.pathname == "/dashboard") {
            appointmentData();
          } else {
            navigate(
              accessPermissonValueGet.includes("DASHBOARD")
                ? "/dashboard"
                : "/manageappointments"
            );
          }

          // window.location.reload()
          // }, 3500);

          // setAppointmentAddUpdate((current) => current + 1);
        } else {
          handleLoaderClose();
          Alert("error", res.error.message);
        }
        handleLoaderClose();
      }
    }
  };

  const slotTime = (e) => {
    // if(formData.consultation_type == "NORMAL") {
    setFormData({
      ...formData,
      slot: e.target.value,
    });
    // }
    if (selectSlotError) {
      setSelectSlotError("");
    }
  };

  // Mobile number validation function

  const mobileOnChange = (e) => {
    if (!validationRegExp.noEmptySpaceStart(e.target.value)) {
      return;
    }
    if (validationRegExp.mobileNumberReg.test(e.target.value)) {
      setFormData({
        ...formData,
        mobile_number: { value: e.target.value, error: false },
      });
      if (mobileError) {
        setMobileError("");
      }
    }
  };

  const fullNameOnChange = (e) => {
    if (!validationRegExp.noEmptySpaceStart(e.target.value)) {
      return;
    }
    var validationCheckName =
      type == "editUser"
        ? validationRegExp.fullNameRegEdit(e.target.value)
        : validationRegExp.fullName(e.target.value);
    if (validationCheckName) {
      setFormData({
        ...formData,
        patient_name: { value: e.target.value, error: false },
      });
      if (patientNameError) {
        setPatientNameError("");
      }
    }
  };

  const parseScanDuration = (value) => {

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    let parseValue = "";

    if(parseInt(formattedHours) > 0) {
      parseValue += parseInt(formattedHours) + " Hr "
    }
    if(parseInt(formattedMinutes) > 0){
      parseValue += parseInt(formattedMinutes) + " Min"
    }
    return parseValue;
  }

  const handleDateChange = (value) => {
    if (slotTimeFromTo.length > 0) {
      setSlotTimeFromTo([]);
    }
    setFormData((prevData) => ({
      ...prevData,
      appointment_date: {
        value: value,
        error: false,
      },
      slot: {
        value: "",
        error: false,
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value, id } = e.target;

    if (!validationRegExp.noEmptySpaceStart(value)) {
      return;
    }

    if (id && !validationRegExp[id](value)) {
      return;
    }
    
    if(name == "repeat_every_time") {
      if(formData?.repeat_every_period?.value == "DAYS") {
        if(parseInt(value) > 30) {
          return;
        }
      }
      else if(formData?.repeat_every_period?.value == "MONTHS") {
        if(parseInt(value) > 12) {
          return;
        }
      }
    }

    if(name == "occurance") {
        if(parseInt(value) > 10) {
          return;
        }
    }

    let updateData = {};
    if (name == "user_uid" || name == "consultation_type") {
      if (slotTimeFromTo.length > 0) {
        setSlotTimeFromTo([]);
        updateData = {
          slot: {
            value: "",
            error: false,
          },
        };
      }
    }

    if (name == "user_uid") {
      const user = users.find((val) => val.user_uid == value);
      setAppointmentUserType(user.user_type == "SCAN" ? "SCAN" : "DOCTOR");
      if(appointmentUserType == "SCAN" && user.user_type == "DOCTOR") {
        updateData = { 
          ...updateData,          
          scan_type: {
            value: "",
            error: false,
          },
          referred_doctor: {
            value: "",
            error: false,
          },
        }
      }
    }

    if(name == "slot" && appointmentUserType == "SCAN") {
      getScanType(value);

      updateData = { 
        ...updateData,          
        scan_type: {
          value: "",
          error: false,
        }
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      ...updateData,
      [name]: {
        value: value,
        error: false,
      },
    }));
  };

  const renderUserType = (value) => {
    // return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().replace("_", " ")
    let formatedValue = value
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formatedValue;
  };

  const disableDates = (date) => {
    const today = dayjs(new Date());
    const tomorrow = today.add(1, "day");
    let disable = true;

    if (formData.consultation_type.value == "NORMAL") {
      disable =
        !date.isSame(formData.edit_appointment_date.value) &&
        !(date.isSame(today, "day") || date.isSame(tomorrow, "day"));
    } else if (formData.consultation_type.value == "EMERGENCY") {
      disable =
        !date.isSame(formData.edit_appointment_date.value) &&
        !date.isSame(today, "day");
    } else if (formData.consultation_type.value == "REVIEW_APPOINTMENT") {
      disable =
        !date.isSame(formData.edit_appointment_date.value) &&
        date.isBefore(today);
    }

    return disable;
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            
          }}
        >
          <Paper
            className="bookmodal"
            sx={{
              width: { xs: "95%", sm: "95%" },
              maxWidth: "700px", // Set the maximum width for larger screens
              margin: "auto",
            }}
          >
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "#EEF0FA",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    padding: { md: "10px 25px", xs: "10px 10px" },
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {type == "editUser"
                      ? "Edit an Appointment"
                      : "Book an Appointment"}
                  </Typography>
                  {/* <Typography
                    sx={{
                      display: { xs: "block", sm: "none" },
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    &nbsp;-&nbsp;
                    {UtilDateTime.formatYearMonthAndDayAsInt(storedDate)}{" "}
                  </Typography> */}
                  {/* <Typography
                    sx={{
                      display: { xs: "none", sm: "block" },
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    &nbsp;-&nbsp;{UtilDateTime.formatMonthDateYear(
                      storedDate
                    )}{" "}
                  </Typography> */}
                </Box>
              </Grid>
              <hr />
              <Grid item md={12} xs={12} sm={12}>
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: { md: "0px 25px", xs: "0px 10px" } }}
                  >
                    <Grid item xs={12}>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={6} sm={3} md={2.5}>
                          <FormLabel
                            sx={{
                              color: "#000000",
                              "&.Mui-focused": { color: "#000000" },
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              fontWeight: "600",
                            }}
                            lab
                          >
                            Consultation Type
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7} sm={9} md={9.5}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={formData?.consultation_type?.value || ""}
                            onChange={handleChange}
                            name="consultation_type"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                                fontWeight: "600",
                              },
                              "& .MuiFormControlLabel-root": {
                                // display: "inline-flex",
                                // alignItems: "start",
                                margin: 0,
                                marginRight: "10px",
                                height: "30px",
                                "& .MuiRadio-root": {
                                  padding: 0, // Custom color for radio button
                                  paddingRight: "9px",
                                },
                              },
                            }}
                          >
                            <FormControlLabel
                              value="NORMAL"
                              disabled={
                                type == "editUser" &&
                                (formData.consultation_type.value ==
                                  "EMERGENCY" ||
                                  formData.consultation_type.value ==
                                    "REVIEW_APPOINTMENT")
                              }
                              control={<Radio size="small" sx={{'&.Mui-checked': { color: "#243665", },}}/>}
                              label="New"
                              className="radioGroupFont"
                            />
                            <FormControlLabel
                              value="EMERGENCY"
                              disabled={
                                type == "editUser" &&
                                formData.consultation_type.value != "EMERGENCY"
                                // ||
                                // storedDate !=
                                //   new Date(currentdate).toISOString()
                                //   ? true
                                //   : false
                              }
                              control={<Radio size="small" sx={{'&.Mui-checked': { color: "#243665", },}}/>}
                              label="Emergency"
                            />
                            <FormControlLabel
                              value="REVIEW_APPOINTMENT"
                              disabled={
                                type == "editUser" &&
                                (formData.consultation_type.value == "NORMAL" ||
                                  formData.consultation_type.value ==
                                    "EMERGENCY")
                              }
                              control={<Radio size="small" sx={{'&.Mui-checked': { color: "#243665", },}}/>}
                              label="Review Appointment"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid
                          container
                          alignItems="center"
                          mb={2}
                          display={
                            (formData.consultation_type.value !=
                              "REVIEW_APPOINTMENT" ||
                              type == "editUser") &&
                            "none"
                          }
                        >
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="availableSlot"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                color: "#000000",
                                // backgroundColor:"red"
                              }}
                            >
                              Appointment Type
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              value={formData?.appointment_type?.value}
                              onChange={handleChange}
                              name="appointment_type"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "14px",
                                },
                                "& .MuiFormControlLabel-root": {
                                  // display: "inline-flex",
                                  // alignItems: "start",
                                  margin: 0,
                                  marginRight: "10px",
                                  height: "30px",
                                  "& .MuiRadio-root": {
                                    padding: 0, // Custom color for radio button
                                    paddingRight: "9px",
                                  },
                                },
                              }}
                            >
                              <FormControlLabel
                                value="SINGLE"
                                control={<Radio size="small" sx={{'&.Mui-checked': { color: "#243665", },}}/>}
                                label="Single"
                              />
                              <FormControlLabel
                                value="MULTIPLE"
                                control={<Radio size="small" sx={{'&.Mui-checked': { color: "#243665", },}}/>}
                                label="Multiple"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>

                        <Grid container mb={2} alignItems="center">
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="availableSlot"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                color: "#000000",
                                // backgroundColor:"red"
                              }}
                            >
                              Doctor Name
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <BasicSelect
                              name="user_uid"
                              value={formData.user_uid.value || "default"}
                              error={formData.user_uid.error}
                              onChange={handleChange}
                              disabled={defaultDoctoUID}
                            >
                              <MenuItem
                                value="default"
                                sx={{ display: "none" }}
                              >
                                <span style={{ color: "#bbb7b7" }}>Select</span>
                              </MenuItem>
                              {users?.map((val, index) => (
                                ((type == "editUser" && editAppointmentUserType == val.user_type) || type != "editUser")  && <BasicMenuItem key={index} value={val.user_uid}>
                                  {val.full_name}
                                </BasicMenuItem>
                              ))}
                            </BasicSelect>
                          </Grid>
                        </Grid>
                        <Grid container mb={2} alignItems="center">
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="e"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                color: "#000000",
                                // backgroundColor:"red"
                              }}
                            >
                              Open Date
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={"en-gb"}
                            >
                              <DesktopDatePicker
                                // sx={{ height: "30px" }}
                                format="DD-MM-YYYY"
                                slotProps={{
                                  textField: {
                                    sx: {
                                      "& .MuiOutlinedInput-root": {
                                        width: "100%",
                                        height: "30px",
                                        "& fieldset": {
                                          borderColor: formData.appointment_date
                                            .error
                                            ? "#D32F2F"
                                            : "#C4C4C4",
                                        },
                                        color: "#000",
                                        fontFamily: "Segoe UI",
                                        "&:hover fieldset": {
                                          borderColor: formData.appointment_date
                                            .error
                                            ? "#D32F2F"
                                            : "#000",
                                        },
                                        "&.Mui-focused fieldset": {
                                          border: formData.appointment_date
                                            .error
                                            ? "1px solid #D32F2F"
                                            : "1px solid #C4C4C4",
                                        },
                                      },
                                    },
                                    size: "small",
                                    inputProps: { readOnly: true },
                                  },
                                  popper: {
                                    sx: {
                                      "& .MuiDateCalendar-root": {
                                        maxHeight: height < "600" ? "290px" : "auto", // Adjust the height here
                                        height: height < "600" ? "290px" : "auto", // Set a specific height
                                        overflow: height < "600" ? "auto":"hidden"
                                      },
                                      // "& .MuiTypography-root": {
                                      //   fontSize: "12px", // Reduce font size for text
                                      // },
                                      // "& .MuiPickersDay-root": {
                                      //   fontSize: "12px", // Reduce font size for day numbers
                                      // },
                                      // "& .MuiDayCalendar-weekContainer": {
                                      //   fontSize: "12px", // Reduce font size for weekdays
                                      // },
                                    },
                                  },
                                }}
                                shouldDisableDate={disableDates}
                                name="appointment_date"
                                value={dayjs(formData.appointment_date.value)}
                                onChange={handleDateChange}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                        <Grid container mb={{md: 2}} alignItems="center">
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="availableSlot"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                color: "#000000",
                                // marginRight: "30px",
                                // backgroundColor:"red"
                              }}
                            >
                              Open Slot
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <BasicSelect
                              value={formData.slot.value || "default"}
                              error={formData.slot.error}
                              name="slot"
                              onChange={handleChange}
                              onOpen={getSlotTime}
                            >
                              {type === "editUser" &&
                              appointmentUserType == "DOCTOR" &&
                              formData.slot.value ? (
                                <BasicMenuItem
                                  sx={{ fontSize: "14px" }}
                                  value={formData.slot.value}
                                >
                                  {UtilDateTime.formatTimeAsHHMMTT(
                                    data?.slot.slot_start_time
                                  ) +
                                    "-" +
                                    UtilDateTime.formatTimeAsHHMMTT(
                                      data?.slot.slot_end_time
                                    )}
                                </BasicMenuItem>
                              ) : (
                                <MenuItem
                                  sx={{ fontSize: "14px", display: "none" }}
                                  value="default"
                                >
                                  <span style={{ color: "#bbb7b7" }}>
                                    Select
                                  </span>
                                </MenuItem>
                              )}

                              {formData?.user_uid?.value &&
                                (appointmentUserType == "DOCTOR"
                                  ? slotTimeFromTo.map((item, i) => (
                                      <BasicMenuItem
                                        sx={{ fontSize: "14px" }}
                                        key={i}
                                        value={item.slot_uid}
                                      >
                                        {UtilDateTime.formatTimeAsHHMMTT(
                                          item.start_time
                                        ) +
                                          "-" +
                                          UtilDateTime.formatTimeAsHHMMTT(
                                            item.end_time
                                          )}
                                      </BasicMenuItem>
                                    ))
                                  : slotTimeFromTo.map((val) => (
                                      <BasicMenuItem value={val}>
                                        {parseScanDuration(val)}
                                      </BasicMenuItem>
                                    )))}

                              {
                                lineProgressLoading && (
                                  <Box sx={{ width: "100%" }}>
                                    <LinearProgress />
                                  </Box>
                                )}

                              {message && (
                                <BasicMenuItem
                                  sx={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    color: "#D32F2F",
                                  }}
                                >
                                  {message}
                                </BasicMenuItem>
                              )}
                            </BasicSelect>
                          </Grid>
                        </Grid>
                        {appointmentUserType == "SCAN" && <Grid container mb={{md: 2}} alignItems="center">
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="availableSlot"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                color: "#000000",
                              }}
                            >
                              Scan Type
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            
                          <Autocomplete
                                  // freeSolo                                  
                                  noOptionsText={
                                    <Typography sx={{ fontSize: "14px" }}>
                                      No scan types found
                                    </Typography>
                                  }
                                  sx={{
                                    width: "100%",
                                    "&.MuiAutocomplete-input": {
                                      padding: "0px",

                                    },
                                    "& .MuiOutlinedInput-root": {
                                      fontSize: "14px",
                                      height: "30px",
                                      color: "#000",
                                      fontFamily: "Segoe UI",
                                      "& fieldset": {
                                        border: '1px solid #C4C4C4 !important',
                                        '&:hover': {
                                          border: '1px solid #000 !important',
                                        },
                                      },
                                    },
                                  }}
                                  value={formData.scan_type.value}
                                  error={formData.scan_type.error}
                                  name="scan_type"
                                  onFocus={()=>{
                                    if(!formData.slot.value) {                                      
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        slot: {
                                          value: formData.slot.value,
                                          error: true
                                        }
                                      }));
                                    }
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        scan_type: {
                                          value: newInputValue,
                                          error: false
                                        }
                                      }));
                                  }}
                                  options={Array.isArray(scanTypes) ? scanTypes : []}
                                  //    && [].length > 0
                                  //     ? scanTypes.map(
                                  //         (option) => option || ""
                                  //       )
                                  //     : ["No Types Available"]
                                  // }
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{
                                        fontSize: "13px",
                                        fontFamily: "Segoe UI",
                                      }}
                                      {...params}
                                      placeholder="Enter Here"
                                    />
                                  )}
                                  renderOption={(props, option) => {
                                    const parseProps = props;
                                    delete parseProps.className
                                    return (
                                    <Box
                                      component="li"
                                      {...parseProps}
                                      sx={{
                                        color: '#000',
                                        fontSize: "14px",
                                        width: "160px",
                                        whiteSpace: "normal", // Allow wrapping
                                        wordWrap: "break-word", // Handle long words
                                        padding: "10px",
                                        paddingRight: "0px"
                                      }}
                                    >
                                      {option}
                                    </Box>
                                  )}}
                                />
                          </Grid>
                        </Grid>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container mb={2}
                          sx={{
                            display: 
                              (formData?.consultation_type?.value ==
                                "REVIEW_APPOINTMENT" &&
                              formData?.appointment_type?.value == "SINGLE") ? {xs: "none", md: "block"} : "none" ,
                              height: "30px"
                          }}>
                            
                        </Grid>
                        <Grid container mb={2} alignItems="center">
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="patientName"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Patient Name*
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <OutlinedTextField
                              id="name"
                              className="patientname"
                              placeholder="Enter Patient Name"
                              name="patient_name"
                              variant="outlined"
                              size="small"
                              error={formData?.patient_name?.error}
                              ref={nameFieldFocus}
                              value={formData?.patient_name?.value || ""}
                              onChange={(e) => fullNameOnChange(e)}
                            />

                            <Typography className="errorValidationTextMobile">
                              {/* {patientNameError} */}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container mb={2} alignItems="center">
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="mobileNumber"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Mobile Number*
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <OutlinedTextField
                              id="mobileNumber"
                              className="mobilenumber"
                              variant="outlined"
                              placeholder="Enter Mobile Number"
                              size="small"
                              name="mobile_number"
                              error={formData?.mobile_number?.error}
                              sx={{
                                width: "100%",
                                // height: "40px",
                                fontSize: "14px",
                                "& .MuiOutlinedInput-root": {
                                  fontSize: "14px",
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                              ref={mobileFieldFocus}
                              value={formData?.mobile_number?.value || ""}
                              onChange={(e) => mobileOnChange(e)}
                            />
                            {
                              <Typography className="errorValidationTextMobilePopupBook">
                                {/* {mobileError} */}
                              </Typography>
                            }
                          </Grid>
                        </Grid>
                        { appointmentUserType == "SCAN" && <Grid container mb={{md: 2}} alignItems="center">
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="availableSlot"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                color: "#000000",
                              }}
                            >
                              Referred By
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                          <Autocomplete
                                  // freeSolo
                                  noOptionsText={
                                    <Typography sx={{ fontSize: "14px" }}>
                                      No users found
                                    </Typography>
                                  }
                                  sx={{
                                    width: "100%",
                                    "&.MuiAutocomplete-input": {
                                      padding: "0px",

                                    },
                                    "& .MuiOutlinedInput-root": {
                                      fontSize: "14px",
                                      height: "30px",
                                      color: "#000",
                                      fontFamily: "Segoe UI",
                                      "& fieldset": {
                                        border: '1px solid #C4C4C4 !important',
                                        '&:hover': {
                                          border: '1px solid #000 !important',
                                        },
                                      },
                                    },
                                  }}
                                  value={formData.referred_doctor.value}
                                  error={formData.referred_doctor.error}
                                  name="referred_doctor"
                                  onInputChange={(event, newInputValue) => {
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        referred_doctor: {
                                          value: newInputValue,
                                          error: false
                                        }
                                      }));
                                  }}
                                  options={
                                    Array.isArray(referredDoctor)
                                      ? referredDoctor.map(
                                          (option) => option.full_name || ""
                                        )
                                      : []
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{
                                        fontSize: "13px",
                                        fontFamily: "Segoe UI",
                                      }}
                                      {...params}
                                      placeholder="Enter Here"
                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      {...props}
                                      sx={{
                                        color: '#000',
                                        fontSize: "14px",
                                      }}
                                    >
                                      {option}
                                    </Box>
                                  )}
                                />
                          </Grid>
                        </Grid>}
                        <Grid
                          container
                          alignItems="center"
                          mb={2}
                          sx={{
                            display:
                              (formData?.consultation_type?.value !=
                                "REVIEW_APPOINTMENT" ||
                              formData?.appointment_type?.value != "MULTIPLE") && "none",
                          }}
                        >
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="repeatEvery"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: mobileError ? "#d32f2f" : "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Repeat Every
                              
                            <Tooltip placement="top" title={formData?.repeat_every_period?.value == "DAYS" ? "Please enter a repeat every value between 1 and 30 Days" : "Please enter a repeat every value between 1 to 12 Months"}>
                              <IconButton sx={{padding: 0, marginLeft: "5px"}}>
                                <InfoOutlinedIcon sx={{ width: "18px" }} />
                              </IconButton>{" "}
                            </Tooltip>
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <Grid container>
                              {/* <Grid item xs={4}> */}
                              <Box sx={{display: "flex", widtg: "100%"}}>
                                <OutlinedTextField
                                  id="number"
                                  variant="outlined"
                                  placeholder="0"
                                  name="repeat_every_time"
                                  size="small"
                                  error={formData?.repeat_every_time?.error}
                                  inputProps={{ maxLength: 4 }}
                                  sx={
                                    {"& .MuiOutlinedInput-root": {
                                      width: "70px"
                                    }}
                                  }
                                  value={
                                    formData?.repeat_every_time?.value || ""
                                  }
                                  onChange={handleChange}
                                />
                                <BasicSelect
                                  value={
                                    formData?.repeat_every_period?.value || ""
                                  }
                                  error={formData?.repeat_every_period?.error}
                                  onChange={handleChange}
                                  name="repeat_every_period"
                                  sx={{ "& .MuiOutlinedInput-notchedOutline": {width: "100%"}, marginLeft: "10px" }}
                                >
                                  <MenuItem
                                    value="default"
                                    sx={{ display: "none" }}
                                  >
                                    <span style={{ color: "#bbb7b7" }}>
                                      Select
                                    </span>
                                  </MenuItem>
                                  {["DAYS", "MONTHS"].map((val) => (
                                    <MenuItem
                                      value={val}
                                      sx={{ fontSize: "14px" }}
                                    >
                                      {renderUserType(val)}
                                    </MenuItem>
                                  ))}
                                </BasicSelect>

                              </Box>
                              {/* </Grid>
                              <Grid item xs={6}> */}
                              {/* </Grid> */}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          mb={2}
                          sx={{
                            display:
                              (formData?.consultation_type?.value !=
                                "REVIEW_APPOINTMENT" ||
                              formData?.appointment_type?.value != "MULTIPLE") && "none",
                          }}
                        >
                          <Grid item xs={4.5} sm={3} md={5}>
                            <FormLabel
                              htmlFor="occurance"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: mobileError ? "#d32f2f" : "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Occurance
                            <Tooltip placement="top" title="Please enter an occurrence value between 1 to 10 ">
                              <IconButton sx={{padding: 0, marginLeft: "5px"}}>
                                <InfoOutlinedIcon sx={{ width: "18px" }} />
                              </IconButton>{" "}
                            </Tooltip>
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7.5} sm={9} md={7}>
                            <OutlinedTextField
                              id="number"
                              className="mobilenumber"
                              variant="outlined"
                              placeholder="0"
                              size="small"
                              name="occurance"
                              error={formData?.occurance?.error}
                              sx={{
                                width: "70px",
                                // height: "40px",
                                fontSize: "14px",
                                "& .MuiOutlinedInput-root": {
                                  fontSize: "14px",
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                              value={formData?.occurance?.value || ""}
                              onChange={handleChange}
                              inputProps={{ maxLength: 4 }}
                            />
                            {
                              <Typography className="errorValidationTextMobilePopupBook">
                                {mobileError}
                              </Typography>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Button
                          sx={{
                            // mt: 3,
                            mb: 2,
                            mr: 2,
                            backgroundColor: "#D13C3C",
                            color: "#FFFFFF",
                            border: "1px solid #FFF",
                            borderRadius: "50px",
                            height: "40px",
                            "&:hover": { background: "#D13C3C" },
                            width: "154px",
                          }}
                          onClick={cancelModel}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          disabled={disabledSubmit}
                          sx={{
                            // mt: 3,
                            mb: 2,
                            backgroundColor: "#41BA8F",
                            color: "#FFFFFF",
                            border: "1px solid #FFF",
                            borderRadius: "50px",
                            height: "40px",
                            "&:hover": { background: "#41BA8F" },
                            width: "154px",
                          }}
                        >
                          {type == "editUser" ? "Submit" : "Confirm"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>

                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loaderopen}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>
      {openCancelModel && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={cancelModel}
        >
          <Backdrop
            open={cancelModel}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Paper sx={{ width: "350px", borderRadius: "10px" }}>
              <Grid sx={{ borderRadius: "15px" }} container>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
                >
                  <Box
                    sx={{
                      height: "70px",
                      backgroundColor: "#FFD7D7",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <IconButton
                      sx={{
                        marginTop: "35px",
                        marginLeft: "142px",
                        backgroundColor: "#D13C3C",
                        height: "54px",
                        width: "55px",
                        "&:hover": { backgroundColor: "#D13C3C" },
                        cursor: "default",
                      }}
                    >
                      <img src={cancellIcon} alt="deleteicon" />
                    </IconButton>
                  </Box>
                </Grid>
                <hr />
                <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Are you sure you want
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          to cancel the activity ?
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "center" }}
                        mt={2}
                      >
                        <Button
                          sx={{
                            mr: 2,
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#000000" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelcloseChild}
                        >
                          No
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "#41BA8F",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#41BA8F" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelclose}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Backdrop>
        </Modal>
      )}
      {/* {openCancelModel && (
        <CancelAppointmentChild
          openModalCancel={cancelModel}
          closeModalCancel={cancelModelclose}
          cancelModelcloseChild={cancelModelcloseChild}
        />
      )} */}
    </>
  );
}
