import { IconButton, InputAdornment, OutlinedInput, styled, TextField } from "@mui/material";

const CustomizedOutlinedTextField = styled(TextField)({
  height: "30px",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#C4C4C4',
    },
    color: "#000",
    fontFamily: "Segoe UI",
    '&:hover fieldset': {
      borderColor: '#000',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #C4C4C4',
    },
  },
});

const CustomizedOutlinedInputField = styled(OutlinedInput)({
  height: "30px",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#C4C4C4',
    },
    color: "#000",
    fontFamily: "Segoe UI",
    '&:hover fieldset': {
      borderColor: '#000',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #C4C4C4',
    },
  },
});

export function OutlinedTextField(props) {
  const { classes, children, sx, startIcon, endIcon, inputProps, error, endAdornment } = props;

  return (
    <CustomizedOutlinedTextField
      fullWidth
      {...props}
      variant="outlined"
      sx={{ ...(sx || {}) }}
      inputProps={{
        ...(inputProps || {}),
        sx: { fontSize: "14px", padding: "0px 12px", ...(inputProps?.sx || {}) }
      }}
      size="small"
    />
  );
}

export function OutlinedInputField(props) {
  const { classes, children, sx, startIcon, endIcon, inputProps, error, endAdornment } = props;

  return (
    <CustomizedOutlinedInputField
      fullWidth
      {...props}
      variant="outlined"
      sx={{ ...(sx || {}) }}
      inputProps={{
        ...(inputProps || {}),
        sx: { fontSize: "14px", padding: "0px 2px 0px 8px", ...(inputProps?.sx || {}) }
      }}
      size="small"
    />
  );
}