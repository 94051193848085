import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import noCueLogo from "../../../Assets/qgliderlogo.png";
import Doctor from "../../../Assets/LoginImage/Login_Qg.png";
import { Avatar, IconButton, InputAdornment } from "@mui/material";
import UserIcon from '../../../Assets/icons/userIcon.svg';
import MobileIcon from '../../../Assets/icons/Mobileicon.svg';
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { MuiOtpInput } from "mui-one-time-password-input";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import EditIcon from '@mui/icons-material/Edit';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Link, useLocation, useNavigate } from "react-router-dom";
import BASE_URL from "../../../Configuration/Config";
import axios from "axios";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import Controller from "../../../Controller/ApiController";
import CancelIcon from "@mui/icons-material/Cancel";
import validationRegExp from "../../../Configuration/ValidationConfig";
import { useDispatch, useSelector } from "react-redux";
import { updateCount } from "../../Assets/Redux/features/updateRequest/updateRequest";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


export default function SignupOtp() {
  
  const {full_name, mobile_number, user_name} = useLocation().state;
  const [username, setUsername] = useState(full_name || "");
  const [mobilenumber, setMobilenumber] = useState(mobile_number || user_name);
  const [otp, setOtp] = useState("");
  const [uid, setUid] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpSentMessage, setOtpSentMessage] = useState("");
  const [ isMobilenumberEdit, setIsMobilenumberEdit ] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const navigate = useNavigate(); 
  const editMobileNumberDefault = mobile_number;
  const [loaderopen, setloaderOpen] = React.useState(false);
  const isMobileNumber = /^[6-9][0-9]{9}$/.test(mobilenumber);
  const isEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{3,}\.[a-z]{2,4}$/.test(
    mobilenumber
  );
  const [error, setError] = useState({mobileNumber : ""});

  const dispatch = useDispatch();

  const updateCountValue = useSelector( (state) => state.updateRequest.update_count)

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const inputIcon = isMobileNumber ? 
  (
    <img src={MobileIcon} style={{color:"#000000"}}/>
  ) : (
    <img src={UserIcon} style={{color:"#000000"}}/>
  ) 

  const handleEditNumber = () =>{
    if(isMobilenumberEdit) {
      setMobilenumber(editMobileNumberDefault);
      setError({mobileNumber: ""});
    }
    setIsMobilenumberEdit(!isMobilenumberEdit);
  }


  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    
  });

  // const sendOTP = () => {
  //   setMinutes(2);
  //   setSeconds(59);
  //   if (isMobileNumber) {
  //       setOtpSentMessage(`OTP has been sent to your mobile number ending with ${mobilenumber.slice(-4)}`);
  //     } else if (isEmail) {
  //       setOtpSentMessage(`OTP has been sent to your email address ${mobilenumber}`);
  //     }
  // };


  const resendOTP = async () => {
    setMinutes(2);
    setSeconds(59);
    let data = isMobilenumberEdit ? { mobile_number: mobilenumber, user_uid: uid} : { user_identifier: mobilenumber}
    //validating the mobile field contains all the same characters 
    if(isMobilenumberEdit){
      if(new RegExp(/^(\d)\1*$/).test(mobilenumber)){
        setError({mobileNumber: "Invalid number"})
        return;
      }
      if(!new RegExp(/^[\d]{10}$/).test(mobilenumber)){
        setError({mobileNumber: "Mobile number must be 10 digit"})
        return;
      }
    }
    try{
      let res = await Controller.ApiController("POST","/doctor/resend_otp","", data);
      // console.log(res);
      if(res.type == "success") {
        setShowOtp(true);
        setIsMobilenumberEdit(false);        
        setOtp(res.otp + "");
      }else if(res.status == "FAILED") {
          if(res.error?.is_duplicate_mobile) {
            Alert("error","Mobile number is already registerd");
          }else {
            Alert("error",res.error.message);
          }
      }
      if (isMobileNumber) {
        setOtpSentMessage(`OTP has been sent to your mobile number ending with ${mobilenumber.slice(-4)}`);
      } else if (isEmail) {
        setOtpSentMessage(`OTP has been sent to your email address ${mobilenumber}`);
      }
    }
    catch(error){
      console.error("Error resending OTP:", error);
    }
  };

  useEffect(() => {
    // if (localStorage.checkbox && localStorage.email !== "") {
    //   setUsername(localStorage.username);
    //   setMobilenumber(localStorage.password);
    // }
    const storedOTP = sessionStorage.getItem("otp" );
    const storedUID = localStorage.getItem( "DoctorUid" );
    if(storedOTP){
      setOtp(storedOTP);
      setUid(storedUID);
    }
  }, []);

  const onChangeValue = (event) => {
   
    if (event.target) {
        const { name, value } = event.target;
        if (name === "username") {
            setUsername(value);
          } else if (name === "mobilenumber") {
            if (new RegExp(/^[\d]{0,10}$/).test(value)) {setMobilenumber(value);
              if(error.mobileNumber) setError({mobileNumber: ""})
            }
          }
    }
    else {
      setOtp(event);
      setOtpSentMessage("");
    }
  };

  const verifyOTP = async () => {
    // console.log(mobilenumber)
    try {
      let response = await Controller.ApiController("POST","/doctor/verify_otp" ,"", { otp: otp, user_identifier: mobilenumber,});
      // console.log(response);
      if (response.type=="success") {
        Alert('success', "OTP verified successfully.")
        Alert('success', "Email verification link has been sent to your email-id. Please click the link and verify.")
        setTimeout(() => {
          navigate('/dashboard');
          dispatch(updateCount({ update_count: updateCountValue + 1 }));
        }, 100);
      } else if(response.status == "FAILED"){
        console.error("Failed to verify OTP",response);
        Alert("error", response.error.message)
      }
    } 
    catch (error) {
      console.error("Error verifying OTP:", error);
      const errorData = error.response.data.message;
      Alert("error", "Incorrect OTP", errorData);
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault(); 
    handleLoaderOpen();   
    // const data = new FormData(event.currentTarget);
    if (isMobilenumberEdit) {
      await resendOTP();
      handleLoaderClose();
    } else {
      await verifyOTP();
      handleLoaderClose();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event && event.keyCode === 13) {
        handleSubmit(event);
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSubmit]);

  return (
    <Grid container component="main" sx={{ height: "100vh", padding: "20px" }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6} elevation={6} sx={{ padding: { xl: "0px 120px", xs: "0px", lg: "0px 100px", md: "0px 50px", }, }} >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <img src={noCueLogo} alt={noCueLogo + ".logo"} width={"155px"}/>
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <h2 sx={{ fontWeight: "600", paddingBottom:"25px" }}>Sign up</h2>
            <Box sx={{marginTop:"20px"}}>
                <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                If you already have an account registered
                </Typography>
                <Box display={"flex"}>
                <Typography>You can</Typography>
                <Link to="/login" style={{ textDecoration: "none", color: "#0C21C1", fontSize: "16px", fontWeight: "600", marginLeft:"4px"}} >
                    Login here !
                </Link>
                </Box>
            </Box>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 4, }}
          >
            { full_name && <TextField
              required
              fullWidth
              id="filled-required"
              label="Full Name"
              name="username"
              value={username}
              placeholder="Enter Full Name"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={UserIcon} style={{color:"#000000"}}/>
                  </InputAdornment>
                ),
                readOnly: true
              }}
              sx={{
                "label.Mui-focused": { color: "#999999" }, "& .MuiInput-underline:after": { borderBottomColor: "#999999" },
                paddingBottom: "4%",
                "& label": { marginTop: "-1%", fontSize: "16px" },
                "& input": {
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#000000",
                },
              }}
              onChange={onChangeValue}
            />}
            <TextField
              required
              fullWidth
              id="filled-required"
              label="Username or Mobile Number"
              name="mobilenumber"
              value={mobilenumber}
              placeholder="Enter Mobile Number"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {inputIcon}
                  </InputAdornment>
                ),
                endAdornment:  validationRegExp.mobileNumberReg.test(mobile_number || user_name) && (
                    <InputAdornment position="end">
                     <Avatar onClick={handleEditNumber} sx={{width:"25px", height:"25px", backgroundColor: isMobilenumberEdit ? "#FFF":"#E77B18", cursor:"pointer"}}>{isMobilenumberEdit ? <CancelIcon  sx={{ fill:"#BC123E", fontSize: "18px", width: "30px", height: "30px", }} /> :<EditIcon sx={{ color: "#fff", fontSize:"18px" }} />}</Avatar>
                  </InputAdornment>
                ),
                readOnly: !isMobilenumberEdit
              }}
              helperText={error.mobileNumber}
              sx={{
                "label.Mui-focused": { color: "#999999" }, "& .MuiInput-underline:after": { borderBottomColor: "#999999" },
                paddingBottom: "4%",  
                "& input::-ms-reveal, & input::-ms-clear": {
                  display: "none",
                },
                "& label": { marginTop: "-1%", fontSize: "16px" },
                "& input": {
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#000000",
                },
                height: isMobilenumberEdit && "110px"
              }}
              error={isMobilenumberEdit && error.mobileNumber}
              onChange={event => onChangeValue(event)}
            />
            { !isMobilenumberEdit && (
              <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <MuiOtpInput
                name="otp"
                value={otp}
                onChange={(event) => onChangeValue(event)}
                TextFieldsProps={{
                  placeholder: "-",
                  inputProps: { style: { color: "#000000", fontSize: "20px" } },
                }}
                height={70}
              />
                {otpSentMessage && (
                    <Typography
                    sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#999999",
                        marginTop: "10px",
                    }}
                    >
                    {otpSentMessage}
                    </Typography>
                )}
            </Box>
             )}
              <div style={{display:"flex", justifyContent:"center"}}>
            <Button
              type="submit"
              fullWidth
              sx={{
                mt: !isMobilenumberEdit && 3,
                mb: 2,
                backgroundColor: "#41BA8F",
                color: "#FFFFFF",
                border: "1px solid #FFF",
                borderRadius: "50px",
                height: "40px",
                width:"200px",
                "&:hover": { background: "#41BA8F" },
                textTransform: "none"
              }}
              // onClick={setOtp}
            >
              { isMobilenumberEdit ? "Submit" : "Verify"}
            </Button></div>


            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ fontSize: "15px", fontWeight: "400" }}>
                Didn’t receive OTP?
              </Typography>
              <Button
                onClick={resendOTP}
                sx={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#0C21C1",
                  textDecoration: "none",
                  marginLeft: "5px",
                  textTransform: "none"
                }}
              >
                Resend
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={false} sm={4} md={6} sx={{ backgroundImage: "url(" + Doctor + ")", backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      />
       <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
    </Grid>
  );
}
