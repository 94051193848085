import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  FormControl,
  Menu,
  MenuItem,
  Button,
  InputLabel,
  Typography,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";

// ------ custom import files --------
import "./TableData.css";
import TableCardData from "../../../Constant/Dashboard.json";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
// import RiEditBoxFill from "../../../../src/Assets/Images/AppoinmentIcon/editIcon.svg";
// import MdDelete from "../../../../src/Assets/Images/DeletePopup";
import BookAppointment from "../../../Popup/BookAppointment";
import DeleteAppointment from "../../../Popup/DeletePopup";
import PropTypes from "prop-types";
import Controller from "../../../Controller/ApiController";
import UtilDateTime from "../../../Constant/utillDateTime";
import { Alert } from "../../../Components/ReuseComponents/Alert/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import delayIcon from "../../../Assets/icons/timeDelay.svg";
import { appointmentReferesh } from "../../Assets/Redux/features/Appointment/appointmentReferesh";
import { BasicSelect } from "../../ReuseComponents/Select/BasicSelect";
import BulkMove from "../../../Popup/BulkMove";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function TableData(props) {
  const dispatch = useDispatch();
  const { opens, filterInQue, setUpdateTopWidget } = props;

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [updateStatusData, setUpdateStatusData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [nextDayDate, setNextDayDate] = useState(false);
  const [users, setUsers] = useState([]);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const storedDate = useSelector((state) => state.dateFilter.date);
  const filterData = useSelector((state) => state.filterData.data);
  const userDetails = useSelector((state) => state.userDetails.data);
  const [reRenderFunction, setReRenderFunction] = useState(false);
  const [sort, setSort] = useState("DESC");
  const [sortBy, setSortBy] = useState("from_time");
  console.log(filterData, "filterDatahufilterData")
  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  const handleSort = () => {
    setSort((prevSort) => (prevSort === "ASC" ? "DESC" : "ASC"));
  };

  const GetAppointmentList = async (pageNumber, perPage) => {
    handleLoaderOpen();
    const currentDate = new Date();
    // Convert to ISO string
    const startOfDayISOString = currentDate.toUTCString();
    let currentdate = new Date();

    currentdate = currentdate.setHours(0, 0, 0, 0);
    if (new Date(currentdate).toISOString() == storedDate) {
      currentdate = new Date().toISOString();
      setNextDayDate(true);
    } else {
      currentdate = storedDate;
      setNextDayDate(false);
    }

    var UID = localStorage.getItem("DoctorUid");
    var appointment_query = {
      "filter.doctor_uid": UID,
      page: pageNumber + 1,
      count: rowsPerPage,
      "filter.slot_status": filterInQue,
      "filter.slot_start_time": startOfDayISOString,
      "filter.keyword": searchKeyword,
      "filter.appointment_date": storedDate,
      sort_by: sortBy,
      sort: sort,
    };

    var slot_query = {
      page: pageNumber + 1,
      count: perPage,
      "filter.appointment_date": storedDate,
      "filter.booking_status": filterInQue,
      "filter.keyword": searchKeyword,
      "filter.doctor_uid": UID,
      sort_by: sortBy,
      sort: sort,
    };

    
    if (userDetails.user_uid && ["SCAN", "DOCTOR"].includes(userDetails.user_type)) {
      slot_query = { ...slot_query, "filter.user_uid": userDetails.user_uid };
    } else if(filterData.user_uid.length > 0) {
      slot_query = { ...slot_query, "filter.user_uid": filterData.user_uid.join(",") };
    } else if(filterData.maped_user_uid.length > 0) {
      slot_query = { ...slot_query, "filter.user_uid": filterData.maped_user_uid.join(",") };
    } else if(userDetails.user_uid && !["SCAN", "DOCTOR"].includes(userDetails.user_type)) {
      slot_query = { ...slot_query, "filter.user_uid": userDetails.user_uid };
    }

    if (filterInQue == "OPEN") {
      slot_query = {
        ...slot_query,
        "filter.slot_start_time": currentdate,
        "filter.current_date": storedDate || "",
        "filter.slot_status": ["OPEN", "BLOCKED"],
        "filter.user_type": "DOCTOR,SCAN",
      };
    }

    let response;
    if (filterInQue == "OPEN") {
      response = await Controller.ApiController(
        "GET",
        `/get_slot/${UID}`,
        "",
        slot_query
      );
    } else {
      response = await Controller.ApiController(
        "GET",
        "/appointment",
        "",
        slot_query
      );
      console.log(response, "response");
    }
    handleLoaderClose();
    if (response.type == "success") {
      setAppointmentData(response);
      // if(filterInQue != "OPEN"){
        
      //   setUsers(response?.users || [{
      //     user_uid: "f0c44200-8b90-11ef-b1bc-4bc4661b0bf4",
      //     full_name: "Kallidos",
      //     user_type: "DOCTOR"
      //   },
      //   {
      //     user_uid: "a8743aa0-8ac3-11ef-84b7-cf024a753460",
      //     full_name: "Jeeva",
      //     user_type: "DOCTOR"
      //   },
      //   {
      //     user_uid: "636e19f0-8aad-11ef-b503-ada488bbbae8",
      //     full_name: "Max",
      //     user_type: "DOCTOR"
      //   }]);
      // }
    } else {
      setAppointmentData({});
    }
    console.log(response, "dashboarddataList");
  };


//   useEffect(() => {
    
//     if(userDetails.filter && filterData.filter) {
//       if(reRenderFunction) {
//       addURLQueryParam(0, 15);
//       GetAppointmentList(0, 15);
//       setPage(0);
//       setRowsPerPage(15);
//     } else {    

//         setReRenderFunction(true);
//     }
//     }
//   console.log(filterData, "filterDatass1111")
//   console.log(userDetails, "filterDatass2222")
// }, [filterInQue, storedDate, filterData, userDetails, sortBy, sort]);

useEffect(() => {

  if(userDetails.filter && filterData.filter) {
    const currentUrl = new URL(window.location.href);
    let pageNumber = currentUrl.searchParams.get("page");
    let perPage = currentUrl.searchParams.get("perPage");
    pageNumber = parseInt(pageNumber) || 0;
    perPage = parseInt(perPage) || 0;
    if((pageNumber > 0 || perPage > 4) && !filterData.pageFilter) {
      GetAppointmentList(pageNumber, perPage);
      addURLQueryParam(pageNumber, perPage);
      setPage(pageNumber);
      setRowsPerPage(perPage)
    }else {
      addURLQueryParam(0, 15);
      GetAppointmentList(0, 15);
      setPage(0);
      setRowsPerPage(15);
    }
  }
  
}, [count, filterInQue, storedDate, filterData, userDetails, sort]);

const initialPage = () => {  
  addURLQueryParam(0, 15);
  GetAppointmentList(0, 15);
  setPage(0);
  setRowsPerPage(15);
}

  const refereshPage = () => {
    setCount((current) => current + 1);
    setUpdateTopWidget((current) => current + 1);
  };
  

  useEffect(() => {
    dispatch(appointmentReferesh(refereshPage));
  }, []);

  const userRequest = async () => {
    try {
      if(!userDetails.admin_uid) {
        return;
      }

      const queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": userDetails.admin_uid

      };

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      console.log(response, "responsemonresponse1");
      if (response.type == "success") {
        console.log(response?.data, "responsemonresponse2");
        setUsers(response?.data || []);
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    if (userDetails.filter) {
      userRequest();
    }
  }, [userDetails]);


  const UpdateStatus = async function (value, data, action) {
    handleLoaderOpen();
    
    let updateData = {};
    let res = {};
    if(action == "USER") {
      updateData = { ...updateData, user_uid: value, appointment_uids:[ data.appointment_uid ], movement_type:"SINGLE"}
      
      res = await Controller.ApiController(
        "POST",
        '/move_appointment',
        "",
        updateData
      );
    } else if(action == "STATUS") {
      updateData = { ...updateData, booking_status: value }
      res = await Controller.ApiController(
        "PUT",
        `/appointment/${data.appointment_uid}`,
        "",
        updateData
      );
    }
    // alert(JSON.stringify(datas));
    // alert(data?.appointment_uid);

    handleLoaderClose();
    if (res) {
      console.log(res, "dataListApi");

      if (res.type === "success") {
        handleClose();
        setCount((current) => current + 1);
        setUpdateTopWidget((current) => current + 1);
        // setAppointmentAddUpdate(prev => alert(prev));
        handleLoaderClose();
        Alert("success", "Appointment updated successfully");
      } else {
        handleLoaderClose();
        Alert("error", res.error.message);
      }
    }
  };

  const handleChange = (event) => {
    console.log("changed", event.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleChangePage = () => {};

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteOpenModal = () => {
    setDeleteModal(true);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    GetAppointmentList(value, rowsPerPage);
    addURLQueryParam(value, rowsPerPage);
    console.log(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    addURLQueryParam(0, event.target.value);
    GetAppointmentList(0, event.target.value);
    setPage(0);
  };

  const convertToTitleCase = (inputString) => {
    const words = inputString.toLowerCase().split("_");

    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString;
  };

  const convertconsultationType = (inputString) => {
    return inputString == "NORMAL"
      ? "New"
      : inputString == "EMERGENCY"
      ? "EMG"
      : inputString == "REVIEW_APPOINTMENT"
      ? "Review"
      : "";
  };


  const handleBulkMoveOpen = () => {
    setCancelModalOpen(true);
  };

  const handleBulkMoveClose = () => {
    setCancelModalOpen(false);
  };

  const addURLQueryParam = (page, rowsPerPage) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", page);
    url.searchParams.set("perPage", rowsPerPage);
    window.history.replaceState({}, '', url);
  };

  const parseUser = (user_type) => { 
    return users.filter((val) => user_type == val.user_type) || [];
  }
  
  const validateConfirmationColor = (data) => {
    let value = "#999999";
    if(data.is_triggered) {
      if(data.is_confirmed) {
        value = "#41BA8F"
      } else if(data.is_cancelled) {
        value = "#BC123E"
      } else if(data.is_rescheduled) {
        value = "#BC123E"
      } else {
        value = "#E77B18"
      }
    }
    
    return value;
  }

  const validateConfirmationTooltip = (data) => {
    let value = "";
    
    if(data.is_triggered) {
      if(data.is_confirmed) {
        value = "Confirmed"
      } else if(data.is_cancelled) {
        value = "Client Cancelled"
      } else if(data.is_rescheduled) {
        value = "Client Rescheduled"
      } else {
        value = "Waiting for Confirmation"
      }
    }else{
      value = "Pending Confirmation";
    }
    
    return value;
  }

  const parseScanDuration = (value) => {

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    let parseValue = " (";

    if(parseInt(formattedHours) > 0) {
      parseValue += parseInt(formattedHours) + "Hr"
    }
    if(parseInt(formattedMinutes) > 0){
      if(parseValue) {
        parseValue += " "
      }
      parseValue += parseInt(formattedMinutes) + "Min"
    }

    parseValue += ")";
    return parseValue;
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              padding: "10px 0px 0px 0px",
              marginBottom: "20px",
              display: { md: "flex", sm: "" },
            }}
          >
            <h3>Appointments</h3>
            {appointmentData?.check_in_status?.patient_name && (
              <div
                style={{
                  display: "flex",
                  marginTop: height > width ? "1.5%" : "",
                }}
              >
                <div
                  style={{
                    marginLeft: height > width ? "1%" : "10%",
                    marginTop: "8px",
                  }}
                >
                  <span class="sun sunshine"></span>
                  <span class="sun"></span>
                </div>
                <span
                  style={{
                    marginLeft: "32px",
                    marginTop: "3px",
                    whiteSpace: "nowrap",
                    fontWeight: 600,
                  }}
                >
                  Patient Checked In :{" "}
                  {appointmentData?.check_in_status?.patient_name || ""} &nbsp;(
                  {appointmentData?.check_in_status?.token || ""})
                </span>
              </div>
            )}
          </Box>
        </Grid>
        <Grid container sx={{display:"flex", justifyContent:"space-around"}}>
        <Grid
          sx={{ padding: "5px 0px 0px 0px", display:"flex", justifyContent:"space-between" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
        >
          <BioDetails filterInQue={filterInQue} />
          {/* <button className="bulk-move-btn" onClick={() => handleBulkMoveOpen()}
          style={{padding:"5px 15px"}}
                    >
            Bulk Move
          </button> */}
          
        </Grid>
        <Grid sx={{ textAlign: { md: "right", xl: "right", lg: "right", xxl: "right", sm:"left", xs:"left"},
            display: "flex", justifyContent: {md:"right", sm:"left", xs:"left"}, marginTop:{sm:2, xs:2}
          }}
          item xs={12} sm={12} md={12} lg={12} xl={4} mb={2}
        >
          <TextField
            autoComplete="new-password"
            aria-label="Search"
            type="text"
            size="small"
            className="searchinput"
            value={searchKeyword}
            placeholder="Search"
            onChange={(e) => {
              setSearchKeyword(e.target.value);
              // if (e.target.value.length >= 1) {
                // GetAppointmentList(0, e.target.value);
                initialPage();
              // }
            }}
            sx={{
              height: "40px",
              paddingTop: "0px",
              borderRadius: "50px",
              marginLeft: { xs: "20px", xs:"0px" },
              backgroundColor: "#EFEFEF",
              "&:hover": {
                borderColor: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#fff",
                display: "none",
              },
              "&:focus": {
                borderColor: "#fff",
                // display:"none"
              },
            }}
            inputProps={{
              style: { fontSize: 14 },
              autocomplete: "off",
              form: {
                autocomplete: "off",
              },
            }}
            InputProps={{
              autoComplete: "new-password",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon sx={{ color: "#757575" }} />
                </InputAdornment>
              ),
              endAdornment: searchKeyword && (
                <InputAdornment>
                  <CancelIcon
                    onClick={() => {setSearchKeyword(""); setCount((prev) => prev + 1);}}
                    sx={{ cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* {height > width ? (
          <Grid item xs={4} sm={4}>
            <Button variant="contained" startIcon={<FilterAltOutlinedIcon />}>
              Filter
            </Button>
          </Grid>
        ) : (
          ""
        )} */}
        </Grid>
      </Grid>
      <Grid sx={{ padding: "5px 0px 0px 0px", marginBottom: { xs: "50px", md: "0px" }, }}
        container
        spacing={1}
      >
        <Grid item sm={12} md={12} lg={12} >
          <Paper sx={{ width: { sm: "100%", xs: "380px" } }}>
            <TableContainer sx={{overflowY:"hidden" }}>
              <Table
                stickyHeader
                sx={{ minWidth: { sm: "800px", xs: "300px",} }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow sx={{ whiteSpace: { sm: "normal", xs: "nowrap" } }}>
                    {TableCardData.tableRowHead.map((RowHeadData, index) => (
                      <TableCell
                        style={{
                          color: "#676666",
                          fontSize: "14px",
                          fontWeight: 600,
                          padding: "5px",
                          // width:"550px",
                          backgroundColor: "#EEF0FA",
                          whiteSpace:"nowrap"
                        }}
                        key={index}
                        onClick={RowHeadData.key === "filter" ? handleSort : null}
                        sx={{
                          cursor: RowHeadData.key === "filter" ? "pointer" : "default",
                        }}
                      >
                        {RowHeadData.TblHead} {RowHeadData.TblHead === "Status"}
                        {RowHeadData.key == "filter" && (
                          <span style={{ marginLeft: 1, position:"relative", top:3 }}>
                          {sort === "ASC" ? <SwapVertIcon sx={{ fontSize: 16 }} /> : <SwapVertIcon sx={{ fontSize: 16 }} />}
                        </span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody stickyHeader>
                  {appointmentData?.data?.length > 0 ? (
                    appointmentData?.data?.map((RowCellData, index) => (
                      <TableRow key={index}>
                        <TableCell className="RowCellData">
                          {index +
                            1 +
                            (appointmentData.current_page - 1) * rowsPerPage}
                        </TableCell>
                        <TableCell
                          sx={{ height: "50px", position: "relative" }}
                          className="RowCellData"
                        >
                          <Tooltip 
                              placement="right"
                              title={`${UtilDateTime.formatTimeAsHHMMTT(
                                RowCellData?.from_time
                              )} To ${UtilDateTime.formatTimeAsHHMMTT(
                                RowCellData?.to_time
                              )}${RowCellData?.scan_slot_duration ? parseScanDuration(RowCellData?.scan_slot_duration) : ""}`}
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#f5f5f9",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    border: "1px solid #dadde9",
                                    "& .MuiTooltip-arrow": {
                                      color: "#f5f5f9",
                                    },
                                  },
                                },
                              }}
                            >
                          {UtilDateTime.formatTimeAsHHMMTT(
                            RowCellData?.from_time ||
                              RowCellData?.slot_start_time ||
                              "--"
                          )}
                          </Tooltip>

                          {RowCellData?.is_delay && (
                            <Tooltip
                              placement="right"
                              title={`Initial booking time is ${UtilDateTime.formatTimeAsHHMMTT(
                                RowCellData?.start_time
                              )}`}
                            >
                              <span style={{ cursor: "pointer" }}>
                                <img
                                  src={delayIcon}
                                  style={{
                                    color: "#2C73EB",
                                    marginLeft: "10px",
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                          {RowCellData.consultation_type == "REVIEW_APPOINTMENT" && <Tooltip title={validateConfirmationTooltip(RowCellData)} placement="top"
                          slotProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#f5f5f9",
                                color: "rgba(0, 0, 0, 0.87)",
                                border: "1px solid #dadde9",
                                "& .MuiTooltip-arrow": {
                                  color: "#f5f5f9",
                                },
                              },
                            },
                          }}
                          >        
                            <CheckCircleIcon sx={{fontSize: "20px", color: validateConfirmationColor(RowCellData), position: "absolute", marginLeft: "5px"}} />
                          </Tooltip>}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {convertconsultationType(
                            RowCellData?.consultation_type
                          ) || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData?.token || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData.patient_name || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData.mobile_number || "--"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData?.manage_user && users.length > 0 && ![
                              "CHECK_IN",
                              "CHECK_OUT",
                              "CANCELLED",
                              "BULK_CANCELLED",
                            ].includes(RowCellData?.booking_status) ? <BasicSelect
                            sx={{ 
                              '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'transparent',
                                },
                                
                            }}
                            value={
                              RowCellData?.manage_user?.user_uid
                            }
                            onChange={(e) => UpdateStatus(e.target.value, RowCellData, "USER",)}
                            inputProps={{
                              sx: { 
                                cursor: [
                                  "CHECK_IN",
                                  "CHECK_OUT",
                                  "CANCELLED",
                                  "BULK_CANCELLED",
                                ].includes(RowCellData?.booking_status) && "default",
                                padding: "0px"
                              }
                            }}
                            readOnly={[
                              "CHECK_IN",
                              "CHECK_OUT",
                              "CANCELLED",
                              "BULK_CANCELLED",
                            ].includes(RowCellData?.booking_status)}
                          >
                            {parseUser(RowCellData.manage_user.user_type).map((val, i) => (
                            <MenuItem
                            key={i}
                              sx={{  fontSize: "14px", display: RowCellData.manage_user.user_uid == val.user_uid ? "none" : "block" }}
                              value={
                                val.user_uid
                              }
                            >
                              {val?.full_name || "--"}
                            </MenuItem>
                            )) }{parseUser(RowCellData.manage_user.user_type).length < 2  && <MenuItem disabled
                              sx={{  fontSize: "14px",}}
                            >
                              No users available
                            </MenuItem>}
                          </BasicSelect> : RowCellData?.manage_user ? RowCellData?.manage_user?.full_name : RowCellData?.manage_user_full_name || "-"}
                        </TableCell>
                        <TableCell className="RowCellData">
                          {RowCellData?.in_que_time != null
                            ? UtilDateTime.formatTimeAsHHMMTT(
                                RowCellData.in_que_time || "---"
                              )
                            : "---"}
                        </TableCell>

                        <TableCell className="RowCellData">
                          {RowCellData?.booking_status &&
                          ![
                            "OPEN",
                            "CHECK_OUT",
                            "CANCELLED",
                            "BULK_CANCELLED",
                          ].includes(RowCellData?.booking_status) ? (
                            <Box>
                              <Button
                                id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, index)}
                                sx={{ padding: "0px" }}
                                aria-readonly
                              >
                                <Box
                                  sx={{
                                    color:
                                      RowCellData.booking_status ==
                                      "NOT_ARRIVED"
                                        ? "#BC123E"
                                        : RowCellData.booking_status == "IN_QUE"
                                        ? "#DA7314"
                                        : RowCellData.booking_status ==
                                          "CHECK_OUT"
                                        ? "#22B175"
                                        : RowCellData.booking_status ==
                                          "CHECK_IN"
                                        ? "#22B175"
                                        : RowCellData.booking_status ==
                                          "CANCELLED"
                                        ? "#ff0000"
                                        : "black",
                                    textAlign: "left",
                                    width: "105px",
                                    textTransform: "none",
                                  }}
                                >
                                  {RowCellData.booking_status &&
                                    convertToTitleCase(
                                      RowCellData?.booking_status
                                    )}
                                </Box>
                                <Box
                                  sx={{
                                    position: "relative",
                                    top: "3px",
                                    color: "black",
                                  }}
                                >
                                  <KeyboardArrowDownIcon />
                                </Box>
                              </Button>
                              {menuIndex == index && (
                                <Menu
                                  sx={{ boxShadow: "none" }}
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >
                                  {RowCellData.booking_status == "IN_QUE" && (
                                    <MenuItem
                                      sx={{ color: "#22B175",fontSize:"14px" }}
                                      onClick={() =>
                                        UpdateStatus("CHECK_IN", RowCellData, "STATUS")
                                      }
                                    >
                                      Check-In
                                    </MenuItem>
                                  )}

                                  {RowCellData.booking_status == "CHECK_IN" && (
                                    <MenuItem
                                      sx={{ color: "#22B175",fontSize:"14px" }}
                                      onClick={() =>
                                        UpdateStatus("CHECK_OUT", RowCellData, "STATUS")
                                      }
                                    >
                                      Check-Out
                                    </MenuItem>
                                  )}

                                  {RowCellData.booking_status ==
                                    "NOT_ARRIVED" &&
                                    nextDayDate && (
                                      <MenuItem
                                        sx={{ color: "#DA7314" ,fontSize:"14px"}}
                                        onClick={() =>
                                          UpdateStatus("IN_QUE", RowCellData, "STATUS")
                                        }
                                      >
                                        In Que
                                      </MenuItem>
                                    )}
                                  {/* {(RowCellData.booking_status == "IN_QUE" ||
                                    RowCellData.booking_status ==
                                      "NOT_ARRIVED") && (
                                    <>
                                      {nextDayDate && <Divider />}
                                      <MenuItem
                                        sx={{ color: "#ff0000" }}
                                        onClick={() =>
                                          UpdateStatus("CANCELLED", RowCellData, "STATUS")
                                        }
                                      >
                                        Cancelled
                                      </MenuItem>
                                    </>
                                  )} */}
                                </Menu>
                              )}
                            </Box>
                          ) : RowCellData?.booking_status ? (
                            <div sx={{}}>
                              <p
                                style={{
                                  fontSize: "14px",
                                  padding: "0px",
                                  height: "0px",
                                  marginBottom: "0px",
                                  marginTop: "-7px",
                                  border: "none",
                                  color:
                                    RowCellData.booking_status == "NOT_ARRIVED"
                                      ? "#BC123E"
                                      : RowCellData.booking_status == "IN_QUE"
                                      ? "#DA7314"
                                      : RowCellData.booking_status ==
                                        "CHECK_OUT"
                                      ? "#22B175"
                                      : RowCellData.booking_status == "CHECK_IN"
                                      ? "#22B175"
                                      : RowCellData.booking_status ==
                                        "CANCELLED"
                                      ? "#ff0000"
                                      : RowCellData.booking_status ==
                                        "BULK_CANCELLED"
                                      ? "#ff0000"
                                      : "black",
                                }}
                              >
                                {convertToTitleCase(
                                  RowCellData?.booking_status
                                )}
                              </p>
                            </div>
                          ) : (
                            <p sx={{ textAlign: "left" }}>
                              {convertToTitleCase(RowCellData?.slot_status)}
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{ textAlign: { md: "center", xs: "start" } }}
                        colSpan={7}
                      >
                        No Appointment Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {appointmentData.total_records > 0 && (
            <TablePagination
              component="div"
              count={appointmentData.total_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[15, 25, 50, 100]}
            />
          )}
        </Grid>
      </Grid>
      {/* {openModal && (<BookAppointment name="EditUser" type="editUser" open={openModal} onClose={handleCloseModal}/>)} */}
      <DeleteAppointment
        openModalDelete={deleteModal}
        closeModalDelete={handleDeleteCloseModal}
      />

      <BulkMove
        open={cancelModalOpen}
        onClose={handleBulkMoveClose}
        // fetchGetAPIData={fetchGetAPI}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );

  // TableData.propTypes = {
  //   page: PropTypes.number,
  //   : PropTypes.function,
  //   list: PropTypes.function,
  // };
}
