import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormHelperText,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./addusers.css";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
import { CheckBox, Dashboard, ForkLeft } from "@mui/icons-material";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useFormAction,
} from "react-router-dom";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import Controller from "../../../Controller/ApiController";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import CreateIcon from "@mui/icons-material/Create";
import deleteIcon from "../../../Assets/icons/Deleteicon.svg";
import InputAdornment from "@mui/material/InputAdornment";
import "./addusers.css";
import UserProfilepageBiodetail from "../../ReuseComponents/Bio/UserProfilepageBiodetail";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmationPop from "../../ReuseComponents/Popup/ConfirmationPop";
import { updateCount } from "../../Assets/Redux/features/updateRequest/updateRequest";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../ReuseComponents/Header/Header";
import "../../../Assets/css/custom-class.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { BasicMenuItem, BasicSelect, MultipleSelect } from "../../ReuseComponents/Select/BasicSelect";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = ["ADMIN", "DOCTOR", "NURSE", "LAB_TECHNICIAN", "RECEPTIONIST"];
const names = [
  {
    name: "Admin",
    value: "ADMIN",
  },
  {
    name: "Doctor",
    value: "DOCTOR",
  },
  {
    name: "Scan",
    value: "SCAN",
  },
  {
    name: "Nurse",
    value: "NURSE",
  },
  {
    name: "Lab Technician",
    value: "LAB_TECHNICIAN",
  },
  {
    name: "Receptionist",
    value: "RECEPTIONIST",
  },
];

const department = [
  "General",
  "Pediatrics",
  "Orthopedics",
  "Cardiology",
  "Neurology",
  "Gynecology",
  "Obstetrics",
  "Oncology",
  "Ophthalmology",
  "Dermatology",
  "Psychiatry",
  "Nephrology",
  "Gastroenterology",
  "Endocrinology",
  "ENT (Ear, Nose, Throat)",
  "Pulmonology",
  "Urology",
  "Anesthesiology",
  "Radiology",
  "Pathology",
  "Emergency Medicine",
  "Surgery",
  "Plastic Surgery",
  "Vascular Surgery",
  "Dental",
  "Physiotherapy",
  "Nuclear Medicine",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};
export default function AddUsers() {
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [Access, setAccess] = useState([]);
  const [UserType, SetUserType] = useState("");
  console.log(UserType, "UserTypeminuiUserType");
  const [EditUserData, setEditUserData] = useState();
  const [mobileNumber, setMobileNumber] = useState("");
  const [RemoveManDatoryDoc, setRemoveManDatoryDoc] = useState(false);
  const [errors, setErrors] = useState({});
  const [RegexErrors, setRegexErrors] = useState({
    userFullName: "",
    mobileNumber: "",
    username: "",
    emailAddress: "",
    password: "",
  });
  const [isEdit, setIsEdit] = useState(true);
  const [ProfileView, setProfileView] = useState(false);
  const [IsProfilePage, setIsProfilePage] = useState(false);
  const CurrentPath = useLocation();
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [clinicName, setClinicName] = useState([]);
  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });
  let UserTypeAccess = localStorage.getItem("user_type");
  let userId = localStorage.getItem("user_uid");
  let dispatch = useDispatch();
  const updateCountValue = useSelector(
    (state) => state.updateRequest.update_count
  );
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  let doctor_id = localStorage.getItem("DoctorUid");
  const navigate = useNavigate();
  console.log(doctor_id);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    city_name: "",
    city_uid: "",
    doctor: "",
    doctor_uid: doctor_id,
    mobile_number: mobileNumber,
    state_name: "",
    state_uid: "",
    address: "",
    user_name: "",
    password: "",
    created_by: "",
    updated_by: "",
    user_uid: "",
    multiple_user_uid: [],
    department: "",
    ivr_contact_number: "",
    hospital_or_clinic_name: "",
    hospital_or_clinic_type: "HOSPITAL",
  });
  let accessLevelArray = [];
  let accessObj = {};
  let { uid } = useParams();
  // let currentUrl = useLocation();

  useEffect(() => {
    const fetchClinicData = async () => {
      try {
        let response = await Controller.ApiController(
          "GET",
          "/clinic?un_registered_clinic=true"
        );
        console.log(response, "hospital_name_list");
        const ClinicData = response.data;
        if (Array.isArray(ClinicData)) {
          setClinicName(ClinicData || []);
        } else console.error("Invalid clinic data format:", ClinicData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchClinicData();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    SetUserType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setErrors((prevErrors) => ({ ...prevErrors, ["doctor"]: "" }));
  };

  useEffect(() => {
    console.log(UserType);
    // accessObj.user_type = UserType

    if (UserType) {
      if (UserType.includes("NURSE") || UserType.includes("RECEPTIONIST") || UserType.includes("LAB_TECHNICIAN")) {
        setRemoveManDatoryDoc(true);
      } else {
        setRemoveManDatoryDoc(false);
      }
    } else {
      setRemoveManDatoryDoc(false);
    }
    console.log(accessObj);
  }, [UserType]);

  const EditField = () => {
    setIsEdit(!isEdit);
  };

  const validateNumber = (val) => {
    let regex = /^[\d]{0,10}$/;

    if (regex.test(val)) {
      return true;
    } else {
      return false;
    }
  };

  const validateIvrNumber = (val) => {
    let regex = /^[\d]{0,15}$/;

    if (regex.test(val)) {
      return true;
    } else {
      return false;
    }
  };

  const mobilePatternSignup = (e) => {
    const { value } = e.target;
    let isValid = validateNumber(e.target.value);
    if (isValid) {
      setFormData({ ...formData, [e.target.name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
    }
  };

  const IvrPatternSignup = (e) => {
    const { value } = e.target;
    let isValid = validateIvrNumber(e.target.value);
    if (isValid) {
      setFormData({ ...formData, [e.target.name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
    }
  };

  const EditUserFetchData = async () => {
    handleLoaderOpen();
    try {
      let res = await Controller.ApiController("GET", "/manage_users/" + uid);
      handleLoaderClose();
      console.log("API Response:", res);
      if (res) {
        setEditUserData(res.data);
        // setFieldEditUser(EditUserData)
        accessLevelArray = res.data.access_permissions;
        setAccess(res.data.access_permissions || []);

        SetUserType(res.data.user_type);
        // setDoctor(res.data.doctor)
        console.log(accessLevelArray);
        // setState(res);
      } else {
        console.log("No user found");
      }
    } catch (error) {
      console.log(error);
      handleLoaderClose();
    }
  };

  const ProfileUSerData = async () => {
    handleLoaderOpen();
    try {
      let url;
      if (UserTypeAccess.includes("SUPER_ADMIN")) {
        url = "/doctor/" + doctor_id;
      } else {
        url = "/manage_users/" + userId;
      }
      // let response = await Controller.ApiController("GET", `/doctor/${Doctor_id}` );
      let response = await Controller.ApiController("GET", url);
      // console.log(response,"hhhhhhhhhhhh")
      handleLoaderClose();
      console.log(response);
      if (response.type === "success") {
        const FetchData = response.data;
        console.log(FetchData, "manageuserdataList");
        setEditUserData(FetchData);
        if (!UserTypeAccess.includes("SUPER_ADMIN")) {
          setAccess(response.data.access_permissions || []);
        }
      } else if (response.status === "FAILED") {
        Alert("error", response.error.message);
      }
    } catch (error) {
      console.log(error);
      handleLoaderClose();
    }
  };

  useEffect(() => {
    accessObj.doctor_uid = doctor_id;
    if (CurrentPath.pathname.includes("Viewusers")) {
      setProfileView(true);
    } else if (CurrentPath.pathname.includes("Profile")) {
      setErrors({});
      setIsProfilePage(true);
      setProfileView(true);
    } else {
      setProfileView(false);
      setIsEdit(false);
    }
    if (
      CurrentPath.pathname.includes("Profile") &&
      UserTypeAccess === "SUPER_ADMIN"
    ) {
      setAccess([
        "SETTINGS",
        "MANAGE_USERS",
        "MANAGE_APPOINTMENTS",
        "DASHBOARD",
        "REPORTS",
      ]);
    }
    async function fetchApi() {
      try {
        let res = await Controller.ApiController("GET", "/state");

        console.log("API Response:", res);
        if (res) {
          setState(res);
        } else console.error("Invalid state data format:", res);
      } catch (error) {
        console.log(error);
      }
    }
    console.log(uid);

    async function getDoctor() {
      handleLoaderOpen();
      try {
        let queryParams = {
          "filter.doctor_uid": doctor_id,
          "filter.user_type": "DOCTOR,SCAN",
        };
        let res = await Controller.ApiController(
          "GET",
          "/manage_users",
          "",
          queryParams
        );
        handleLoaderClose();
        console.log(res.data, "datalistapinew");
        if (res && res.data && Array.isArray(res.data)) {
          const doctorList = res.data.map((item) => ({
            user_uid: item.user_uid,
            full_name: item.full_name,
          }));
          console.log(doctorList);

          // Setting the extracted doctor data to state
          setDoctor(doctorList);
        }
      } catch (error) {
        console.log(error);
        handleLoaderClose();
      }
    }

    fetchApi();
    if (
      (CurrentPath.pathname.includes("Editusers") ||
        CurrentPath.pathname.includes("Viewusers")) &&
      uid
    ) {
      EditUserFetchData();
    } else if (CurrentPath.pathname.includes("Profile")) {
      ProfileUSerData();
    }
    getDoctor();
  }, [CurrentPath]);

  useEffect(() => {
    setFieldEditUser(EditUserData);
  }, [EditUserData]);

  const openPopup = (PopupType, Data) => {
    if (PopupType == "Cancel") {
      setPopUpData({
        ...popupData,
        Purpose: "Cancel",
        message: "Are you sure you want to cancel this activity?",
      });
    } else {
      setPopUpData({
        ...popupData,
        Purpose: "Delete",
        message: "Are you sure you want to delete the user?",
        DataDetails: Data ? Data : "",
      });
    }
    setOpen(true);
  };
  const yesSubmit = (Data) => {
    if (Data.Purpose == "Cancel") {
      cancelUser();
      // if(CurrentPath.pathname.includes("Profile")){
      // navigate("/dashboard")}
    } else {
      DeleteUser(Data.DataDetails);
    }
    setOpen(false);
  };
  const PopUpClose = () => {
    setOpen(false);
  };
  const DeleteUser = async (uid) => {
    console.log(uid);
    handleLoaderOpen();
    if (uid) {
      let res = await Controller.ApiController(
        "DELETE",
        "/manage_users/" + uid
      );
      handleLoaderClose();
      console.log(res);
      if (res.type == "success") {
        Alert("success", "User deleted successfully");
        navigate("/manageusers");
      }
    }
  };
  const setFieldEditUser = (EditUserData) => {
    // alert(JSON.stringify(EditUserData))
    if (EditUserData) {
      setFormData({
        ...formData,
        full_name: EditUserData.full_name ? EditUserData.full_name : "",
        mobile_number: EditUserData.mobile_number
          ? EditUserData.mobile_number
          : "",
        user_type: EditUserData.user_type ? EditUserData.user_type : "",
        hospital_or_clinic_name: EditUserData.hospital_or_clinic_name
          ? EditUserData.hospital_or_clinic_name
          : "default",
        // ? EditUserData.hospital_or_clinic_name
        // : "",
        ivr_contact_number: EditUserData.ivr_contact_number
          ? EditUserData.ivr_contact_number
          : "",
        email: EditUserData.email ? EditUserData.email : "",
        doctor_uid: EditUserData?.user_type == "SUPER_ADMIN" ? EditUserData.doctor_uid : EditUserData.maped_doctor
          ? EditUserData.maped_doctor.user_uid //Validate
          : "",
        state_uid: EditUserData.state ? EditUserData.state.state_uid : "",
        state_name: EditUserData.state ? EditUserData.state.state_name : "",
        city_name: EditUserData.city ? EditUserData.city.city_name : "",
        city_uid: EditUserData?.city?.city_uid
          ? EditUserData.city.city_uid
          : "",
        city: EditUserData.city ? EditUserData.city : "",
        address: EditUserData.address ? EditUserData.address : "",
        user_name: EditUserData.user_name ? EditUserData.user_name : "",
        department: EditUserData.department ? EditUserData.department : "",
        // user_uid:EditUserData.user_uid ? EditUserData.user_uid : ""
        user_uid: EditUserData.maped_doctor
          ? EditUserData.maped_doctor.user_uid //Validate
          : "",
        multiple_user_uid: Array.isArray(EditUserData.maped_doctor)
          ? EditUserData.maped_doctor.map((val) => ( {user_uid: val.user_uid, full_name: val.full_name} )) //Validate
          : []
      });

      SetUserType(EditUserData.user_type);
      if (EditUserData.city) {
        setCityData();
      }

      // setCity(EditUserData.city)
    }
  };

  const findDuplicates = (arr, key) => {
    const countMap = arr.reduce((acc, item) => {
      const keyValue = item[key];
      acc[keyValue] = (acc[keyValue] || 0) + 1;
      return acc;
    }, {});
  
    return arr.filter(item => countMap[item[key]] > 1);
  };

  const onChangeUserType = (event) => {
    const { value } = event.target;
    SetUserType(value);

    setErrors((prevErrors) => ({ ...prevErrors, ["user_type"]: "" }));
    // setFormData({ ...formData, userType: Array.isArray(event.target.value) ? event.target.value : [event.target.value] });
  };

  const onChangeDoctor = (event) => {
    const { value, name } = event.target;
    console.log(formData, value, "formDatainformData");
    
    let filerValue = typeof value === "string" ? value.split(",") : value;
    filerValue = filerValue.filter((val) => val != "Select");

    const countMap = findDuplicates(value, "user_uid")
  
    // return arr.filter(item => countMap[item[key]] > 1);

    filerValue = filerValue.filter((val) => !countMap.some((user) => user.user_uid == val.user_uid))
    console.log(formData, filerValue,countMap, "formDatainformData");
    setFormData({ ...formData, multiple_user_uid: filerValue });
    setErrors((prevErrors) => ({ ...prevErrors, ["doctor"]: "" }));
  };

  const onChangeCity = (event) => {
    let city = event.target.value;
    let cityUid = event.target.value;
    setFormData({ ...formData, city_uid: cityUid, city: city });
    setErrors((prevErrors) => ({ ...prevErrors, ["city_name"]: "" }));
  };
  const setCityData = async () => {
    try {
      if (EditUserData.state.state_uid) {
        let res = await Controller.ApiController(
          "GET",
          "/city/" + EditUserData.state.state_uid
        );

        console.log("API Response:", res);
        if (res) {
          setCity(res);
        } else {
          setCity(["No city Found"]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeState = async (event) => {
    let uid = event.target.value;
    // setFormData({ ...formData, state_name: event.target.value.state_name, state_uid:uid});
    setFormData({ ...formData, state_uid: uid });
    try {
      let res = await Controller.ApiController("GET", "/city/" + uid);

      console.log("API Response:", res);
      if (res) {
        setCity(res);
      } else {
        setCity(["No city Found"]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const RegexValidation = (name, value) => {
    let validInput = true;
    let errorMessage = "";

    if (name === "full_name") {
      if (value) {
        validInput = /^(?! )[a-zA-Z ]{0,}$/.test(value);
        errorMessage = validInput ? "" : "Invalid full name";
        setRegexErrors({ userFullName: errorMessage });
      } else {
        setRegexErrors({ userFullName: "" });
      }
    }

    if (name === "user_email_id") {
      if (value) {
        validInput =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          );
        errorMessage = validInput ? "" : "Invalid email address";
        setRegexErrors({ emailAddress: errorMessage });
      } else {
        setRegexErrors({ emailAddress: "" });
      }
    }
    if (name === "password") {
      if (value) {
        validInput =
          /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/.test(
            value
          );
        errorMessage = validInput ? "" : "Invalid password";
        setRegexErrors({ password: errorMessage });
      } else {
        setRegexErrors({ password: "" });
      }
    }
    if (name === "user_name") {
      if (value) {
        validInput = /^[a-zA-Z0-9]{8,20}$/.test(value);
        errorMessage = validInput ? "" : "Invalid username";
        setRegexErrors({ username: errorMessage });
      } else {
        setRegexErrors({ password: "" });
      }
    }
  };

  const onChangeValue = (event) => {
    const { name, value } = event.target;

    // RegexValidation(name , value)

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const onChangeHospital = (event) => {
    let errorMessage = "";
    setFormData({ ...formData, hospital_or_clinic_name: event.target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      hospital_or_clinic_name: formData.hospital_or_clinic_name
        ? ""
        : errorMessage,
    }));
  };

  const validation = () => {
    const formErrors = {};
    if (!formData.full_name || !formData.full_name.trim()) {
      // formErrors.user_full_name = "Full Name is required"
      formErrors.full_name = true;
    } else {
      if (formData.full_name) {
        let validInput = /^(?! )[a-zA-Z ]{0,}$/.test(formData.full_name);
        if (!validInput) {
          formErrors.full_name = "Invalid full name";
        }
      }
    }

    if (
      (UserType.includes("DOCTOR") || UserType.includes("SCAN")) &&
      (!formData.department || formData.department.trim() === "")
    ) {
      formErrors.department = true;
    }

    if (uid) {
      if (
        !UserType
        // || (UserType.length === 1 && UserType.includes("Select the User Type"))
      ) {
        // formErrors.user_type = "User Type is required"
        formErrors.user_type = true;
      }
    } else {
      if (
        !UserType
        // || (UserType.length === 1 && UserType.includes("Select the User Type"))
      ) {
        // formErrors.user_type = "User Type is required"
        formErrors.user_type = true;
      }
    }

    if(UserType.includes("SUPER_ADMIN") && (!formData.mobile_number || !formData.mobile_number.trim()) ){
      // formErrors.user_mobile_number = "Mobile Number is required"
      formErrors.mobile_number = true;
    }
    if (
      (UserType.includes("SUPER_ADMIN") && !formData.city) ||
      (UserType.includes("SUPER_ADMIN") && !formData.city.trim())
    ) {
      formErrors.city_name = "City is required";
      formErrors.city = true;
    }
    if (
      (UserType.includes("SUPER_ADMIN") && !formData.address) ||
      (UserType.includes("SUPER_ADMIN") && !formData.address.trim())
    ) {
      formErrors.address = "Address is required";
      formErrors.address = true;
    }

    if (RemoveManDatoryDoc) {
      if (!formData.multiple_user_uid || formData.multiple_user_uid.length === 0) {
        formErrors.doctor = true;
      }
      else if (UserType.includes("RECEPTIONIST") && (!formData.multiple_user_uid || formData.multiple_user_uid.length === 0)) {       
        formErrors.doctor = true;
      }
    }
    

    if (!formData.user_name || !formData.user_name.trim()) {
      // formErrors.user_name = "User Name is required"
      formErrors.user_name = true;
    } else {
      if (formData.user_name) {
        let validInput = /^[a-zA-Z0-9]{8,20}$/.test(formData.user_name);
        if (!validInput) {
          formErrors.user_name = "Invalid username";
        }
      }
    }

    if (
      !(
        CurrentPath.pathname.includes("Editusers") ||
        CurrentPath.pathname.includes("Viewusers") || CurrentPath.pathname.includes("Profile")
      )
    ) {
      if (!formData.password || !formData.password.trim()) {
        formErrors.password = true;
      } else {
        let validInput = /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/.test(
          formData.password
        );
        if (!validInput) {
          formErrors.password = "Invalid password";
        }
      }
    }
    if(CurrentPath.pathname.includes("add"))
    if (formData.mobile_number) {
      if (formData.mobile_number.length != 10) {
        formErrors.mobile_number = "Mobile number must be 10 digit";
      }
      if (new RegExp(/^(\d)\1*$/).test(formData.mobile_number)) {
        formErrors.mobile_number = "Invalid Mobile number";
        // return true;
      }
    }
    if (
      CurrentPath.pathname.includes("Profile") &&
      UserTypeAccess === "SUPER_ADMIN"
    ) {
      if (!formData.email || !formData.email.trim()) {
        formErrors.email = true;
      }
      if (formData.email) {
        let validInput =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            formData.email
          );
        if (!validInput) {
          formErrors.email = "Invalid email address";
        }
      }
    } else {
      if (formData.email) {
        let validInput =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            formData.email
          );
        if (!validInput) {
          formErrors.email = "Invalid email address";
        }
      }
    }

    if (
      CurrentPath.pathname.includes("Profile") &&
      UserTypeAccess === "SUPER_ADMIN"
    ) {
      if (!formData.ivr_contact_number || !formData.ivr_contact_number.trim()) {
        // formErrors.password = "Password is required"
        formErrors.ivr_contact_number = true;
      } else {
        if (
          formData.ivr_contact_number.length < 8 ||
          formData.ivr_contact_number.length > 15
        ) {
          formErrors.ivr_contact_number = "IVR number must be 8 to 15 digit";
        }
      }

      if (
        !formData.hospital_or_clinic_name ||
        !formData.hospital_or_clinic_name.trim()
      ) {
        formErrors.hospital_or_clinic_name = true;
      }
    }

    // if(Access.length === 0){
    //     formErrors.accessError = true;
    // }

    const hasErrors = Object.keys(formErrors).length > 0;
    console.log(formErrors, "isValidData");
    setErrors(formErrors);
    return !hasErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleLoaderOpen();
    let isValidData = validation();

    console.log(isValidData,"isValidData");
    // setFormData({doctor_uid:doctor_id})
    if (UserType.length > 0) {
      accessObj.user_type = UserType;
    }
    // if (Access.length > 0) {
    accessObj.access_permissions = Access;
    // }

    let data = { ...formData, ...accessObj };
    data.multiple_user_uid = data.multiple_user_uid.map((val) => val.user_uid)
    console.log(data);
    if (isValidData) {
      if (CurrentPath.pathname.includes("addusers")) {
        // ------------ for new user adding -------------
        // delete data["user_uid"]
       
        let res = await Controller.ApiController(
          "POST",
          "/manage_users",
          "",
          data
        );
        handleLoaderClose();
        console.log(res);
        if (res) {
          if (res.type === "success") {
            Alert("success", "User added successfully");
            dispatch(updateCount({ update_count: updateCountValue + 1 }));
            navigate("/manageusers");
            clearData();
          } else if (res.error.type == "error") {
            if (res.error.data) {
              dupliCateValidation(res.error.data);
            } else {
              Alert("error", res?.error?.message || "Something went wrong");
            }
          } else {
            Alert("error", "Something went wrong");
          }
        }
      } else if (
        (CurrentPath.pathname.includes("Editusers") ||
          CurrentPath.pathname.includes("Viewusers")) &&
        uid
      ) {
        // ------------ for edit user updating -------------
        delete data["doctor_uid"];
        delete data["doctor"];
        delete data["city"];
        delete data["password"];
        
        let res = await Controller.ApiController(
          "PUT",
          "/manage_users/" + EditUserData.user_uid,
          "",
          data
        );
        console.log(res);
        if (res) {
          if (res.type === "success") {
            Alert("success", "User updated successfully");
            dispatch(updateCount({ update_count: updateCountValue + 1 }));
            navigate("/manageusers");
          } else if (res.error.type == "error") {
            if (res.error.data) {
              dupliCateValidation(res.error.data);
            } else {
              Alert("error", res?.error?.message || "Something went wrong");
            }
          } else {
            Alert("error", "Something went wrong");
          }
        }
      } else {
        if (UserTypeAccess === "SUPER_ADMIN") {
          delete data["doctor"];
          // delete data["user_uid"];
          delete data["user_type"];
          delete data["access_permissions"];
          delete data["password"];
        } else {
          delete data["doctor_uid"];
          delete data["doctor"];
          delete data["ivr_contact_number"];
          delete data["hospital_or_clinic_name"];
          delete data["hospital_or_clinic_type"];
          delete data["password"];
          delete data["city"];
        }
        let res;
        if (UserTypeAccess === "SUPER_ADMIN") {
          
          handleLoaderOpen();
          res = await Controller.ApiController(
            "PUT",
            "/doctor/" + doctor_id,
            "",
            data
          );
          handleLoaderClose();
        } else {
          res = await Controller.ApiController(
            "PUT",
            "/manage_users/" + userId,
            "",
            data
          );
        }
        // handleLoaderClose();
        console.log(res);
        if (res) {
          if (res.type === "success") {
            Alert("success", "Profile updated successfully");
            dispatch(updateCount({ update_count: updateCountValue + 1 }));
            // navigate("/dashboard");
            setIsEdit(true);
          } else if (res.error.type == "error") {
            if (res.error) {
              dupliCateValidation(res.error.data);

              // Alert("error", res.error.message);
            } else {
              Alert("error", res?.error?.message || "Something went wrong");
            }
          } else {
            Alert("error", "Something went wrong");
          }
        }
      }
    }

    handleLoaderClose();
  };
  const dupliCateValidation = (Error) => {
    let duplicateError = {};
    if (Error) {
      if (Error.email && Error.email == true) {
        duplicateError.email = "Email is already registered";
      }
      if (Error.mobile_number && Error.mobile_number == true) {
        duplicateError.mobile_number = "Mobile number  is already registered";
      }
      if (Error.user_name && Error.user_name == true) {
        duplicateError.user_name = "Username is already registered";
      }
      if (Error.ivr_contact_number && Error.ivr_contact_number == true) {
        duplicateError.ivr_contact_number = "IVR number is already registered";
      }
    }
    setErrors(duplicateError);
  };
  const clearData = () => {
    setFormData({
      user_name: "",
      user_full_name: "",
      user_email_id: "",
      password: "",
      user_mobile_number: "",
      ivr_contact_number: "",
      hospital_or_clinic_name: "",
      user_uid: "",
      multiple_user_uid: "",
      city_uid: "",
      state_uid: "",
      address: "",
    });
    SetUserType([]);
  };
  const cancelUser = () => {
    if (CurrentPath.pathname.includes("Profile")) {
      navigate("/Profile");
      setIsEdit(true);
    } else if (CurrentPath.pathname.includes("Viewusers")) {
      setIsEdit(!isEdit);
      EditUserFetchData();
    } else {
      navigate("/manageusers");
    }
  };
  const changeAccessLevel = (event) => {
    if (!UserType.includes("SUPER_ADMIN")) {
      console.log(event.target.checked);
      if (!isEdit) {
        const { value, checked } = event.target;
        if (checked) {
          setAccess((prevSelected) => [...prevSelected, value]);
        } else {
          setAccess((prevSelected) =>
            prevSelected.filter((permission) => permission !== value)
          );
        }
      }
    }
  };

  const renderUserType = (value) => {
    let formatedValue = value
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(", ")
      .replace("_", " ");
    return formatedValue;
  };

  return (
    <div style={{ marginTop: "10px", marginBottom: "25px" }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ display: { xs: "", sm: "none" } }}
        >
          <Header />
        </Grid>
      </Grid>
      {CurrentPath.pathname.includes("Profile") ? (
        <BreadCrumbs
          crumbs={["Dashboard", "Profile"]}
          paths={["/dashboard", "/Profile"]}
          selected={(crumb) => console.log("hello", crumb)}
        />
      ) : (
        ""
      )}

      {CurrentPath.pathname.includes("addusers") ? (
        <BreadCrumbs
          crumbs={["Dashboard", "Manage Users", "Add Users"]}
          paths={["/dashboard", "/manageusers", "/manageusers/addusers"]}
          selected={(crumb) => console.log("hello", crumb)}
        />
      ) : (
        ""
      )}

      {CurrentPath.pathname.includes("Editusers") ? (
        <BreadCrumbs
          crumbs={["Dashboard", "Manage Users", "Edit Users"]}
          paths={["/dashboard", "/manageusers", "/manageusers/Editusers"]}
          selected={(crumb) => console.log("hello", crumb)}
        />
      ) : (
        ""
      )}

      {CurrentPath.pathname.includes("Viewusers") ? (
        <BreadCrumbs
          crumbs={["Dashboard", "Manage Users", "View Users"]}
          paths={["/dashboard", "/manageusers", "/manageusers/Viewusers"]}
          selected={(crumb) => console.log("hello", crumb)}
        />
      ) : (
        ""
      )}

      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12} xl={12} sx={{ marginTop: "20px" }}>
          <BioDetails />
          {/* <UserProfilepageBiodetail /> */}
        </Grid>
      </Grid>
      <Box sx={{ padding: "20px 10px 20px" }}>
        {ProfileView == true ? (
          <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Typography sx={{ fontSize: "23px", fontWeight: "400" }}>
              {" "}
              {CurrentPath.pathname.includes("Profile")
                ? "Profile Information"
                : "User Information"}
            </Typography>

            {isEdit ? (
              <Tooltip
                title="Edit"
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#f5f5f9",
                      color: "rgba(0, 0, 0, 0.87)",
                      border: "1px solid #dadde9",
                      "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                    },
                  },
                }}
              >
                <Box
                  onClick={EditField}
                  sx={{
                    backgroundColor: "#243665",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "27px",
                    height: "27px",
                    borderRadius: "17px",
                    marginLeft: "10px",
                    cursor: "pointer",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                >
                  {" "}
                  <Button size="small" sx={{ minWidth: "auto" }}>
                    <CreateIcon sx={{ fill: "white", height: "17px" }} />
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              ""
            )}
            {!CurrentPath.pathname.includes("Profile") ? (
              <Tooltip
                title="Delete"
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#f5f5f9",
                      color: "rgba(0, 0, 0, 0.87)",
                      border: "1px solid #dadde9",
                      "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                    },
                  },
                }}
              >
                <Box
                  onClick={() => openPopup("Delete", EditUserData.user_uid)}
                  sx={{
                    backgroundColor: "#BC123E",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "27px",
                    height: "27px",
                    borderRadius: "17px",
                    cursor: "pointer",
                  }}
                >
                  <Button size="small" sx={{ minWidth: "auto" }}>
                    <DeleteOutlinedIcon
                      sx={{ color: "#fff", fontSize: "18px" }}
                    />
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <Typography sx={{ fontSize: "23px", fontWeight: "400" }}>
            {CurrentPath.pathname.includes("Profile")
              ? "Profile Information"
              : "User Information"}
          </Typography>
        )}

        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container gap={1} >
              <Grid item md={5.8} xs={12} >
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "37px",
                      }}
                    >
                      <Typography
                        htmlFor="user_full_name"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                        }}
                      >
                        Full Name&nbsp;*
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      id="user_full_name"
                      placeholder="Enter your Full Name"
                      required
                      name="full_name"
                      error={!!errors.full_name}
                      value={formData.full_name ? formData.full_name : ""}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: isEdit,
                        fontSize: "14px",
                      }}
                      inputProps={{ maxLength: 50, style: { fontSize: 14 } }}
                      sx={{
                        width: "100%",
                        height: "37px",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                        // marginTop:"3px",
                        font: "menu",
                      }}
                      helperText={
                        <FormHelperText
                          sx={{
                            fontSize: "12px",
                            marginLeft: "-13px",
                            marginTop: "-5px",
                          }}
                        >
                          {errors.full_name ? errors.full_name : ""}
                        </FormHelperText>
                      }
                      onChange={onChangeValue}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5.8} xs={12} >
                <Grid container mt={{ xs: 0, md: 0 }} columnSpacing={1}>
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "37px",
                      }}
                    >
                      <Typography
                        htmlFor="user_mobile_number"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                        }}
                      >
                       {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? "Mobile Number *" : "Mobile Number"} 
                        {/* &nbsp;* */}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      id="user_mobile_number"
                      placeholder="Enter your Mobile Number"
                      required
                      error={!!errors.mobile_number}
                      InputProps={{
                        readOnly: isEdit,
                      }}
                      inputProps={{ style: { fontSize: 14 } }}
                      variant="outlined"
                      size="small"
                      sx={{ width: "100%", height: "30px", fontSize: "14px" }}
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={(e) => {
                        mobilePatternSignup(e);
                      }}
                      helperText={
                        <FormHelperText
                          sx={{
                            fontSize: "12px",
                            marginLeft: "-13px",
                            marginTop: "-5px",
                          }}
                        >
                          {errors.mobile_number ? errors.mobile_number : ""}
                        </FormHelperText>
                      }
                    />
                  </Grid>
              </Grid>
              </Grid>
          </Grid>
          {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
          <Grid container gap={1}  >
          <Grid item md={5.8} xs={12}  >
              {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
                <Grid container mt={{ xs: 2, md: 5 }}  columnSpacing={1}>
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "37px",
                      }}
                    >
                      <Typography
                        htmlFor="contact_ivr_number"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                        }}
                      >
                        IVR Contact Number&nbsp;*
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      id="contact_ivr_number"
                      placeholder="Enter contact IVR number"
                      required
                      error={!!errors.ivr_contact_number}
                      InputProps={{
                        readOnly: isEdit,
                      }}
                      variant="outlined"
                      size="small"
                      sx={{ width: "100%", height: "37px" }}
                      name="ivr_contact_number"
                      inputProps={{ style: { fontSize: 14 } }}
                      value={formData.ivr_contact_number}
                      onChange={(e) => {
                        IvrPatternSignup(e);
                      }}
                      helperText={
                        <FormHelperText
                          sx={{
                            fontSize: "12px",
                            marginLeft: "-13px",
                            marginTop: "-5px",
                            color: "#d32f2f",
                          }}
                        >
                          {errors.ivr_contact_number
                            ? errors.ivr_contact_number
                            : ""}
                        </FormHelperText>
                      }
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
          </Grid>
          <Grid item md={5.8} xs={12} >
            {IsProfilePage == true && UserTypeAccess === "SUPER_ADMIN" ? (
              <Grid container mt={{ xs: 2, md: 5 }} columnSpacing={1}>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "37px",
                    }}
                  >
                    <Typography
                      htmlFor="hospital_clinic_number"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                        // whiteSpace:window.innerWidth > 1279 && window.innerWidth < 1283 ? "nowrap" : ""
                      }}
                    >
                      Hospital/Clinic Name&nbsp;*
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    id="hospital_clinic_number"
                    placeholder="Enter hospital or clinic name"
                    required
                    error={!!errors.hospital_or_clinic_name}
                    InputProps={{
                      readOnly: true,
                    }}
                    inputProps={{ style: { fontSize: 14 } }}
                    variant="outlined"
                    size="small"
                    sx={{ width: "100%", height: "37px" }}
                    name="hospital_or_clinic_name"
                    value={formData.hospital_or_clinic_name}
                    onChange={onChangeValue}
                    helperText={
                      <FormHelperText
                        sx={{
                          fontSize: "12px",
                          marginLeft: "-13px",
                          marginTop: "-5px",
                        }}
                      >
                        {errors.hospital_or_clinic_name
                          ? errors.hospital_or_clinic_name
                          : ""}
                      </FormHelperText>
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          </Grid>
          ):("")}

          <Grid container gap={1} mt={{ xs: 2, md: 5 }} >
              <Grid item md={5.8} xs={12} >
                <Grid container columnSpacing={1}>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "37px",
                    }}
                  >
                    <Typography
                      htmlFor="usertype"
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      User Type&nbsp;*
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  {CurrentPath.pathname.includes("Profile") &&
                  UserTypeAccess === "SUPER_ADMIN" ? (
                    <TextField
                      id="usertype"
                      disabled
                      name="usertype"
                      value={
                        UserType && UserType === "SUPER_ADMIN" ? "ADMIN" : ""
                      }
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: isEdit,
                      }}
                      inputProps={{
                        maxLength: 50,
                        style: { fontSize: "14px" },
                      }}
                      sx={{
                        width: "100%",
                        height: "37px",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    />
                  ) : (
                    <Select
                      labelId="multiple-checkbox-label"
                      disabled={UserTypeAccess == "SUPER_ADMIN" && CurrentPath.pathname.includes("addusers") ? false : true}
                      id="usertype"
                      size="small"
                      // multiple
                      value={UserType || "default"}
                      onChange={onChangeUserType}
                      error={!!errors.user_type}
                      readOnly={isEdit}
                      // renderValue={(selected) => selected.join(", ").replace("_"," ")}
                      // renderValue={(selected)=>renderUserType(selected)}
                      sx={{
                        width: "100%",
                        height: "37px",
                        fontSize: "14px",
                      }}
                    >
                      <MenuItem
                        value="default"
                        disabled
                        style={{ display: "none" }}
                      >
                        <span style={{ color: "#bbb7b7" }}>Select</span>
                      </MenuItem>
                      {names.map((name) => (
                        <MenuItem key={name.value} value={name.value} >
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
                </Grid>
              </Grid>
              <Grid item md={5.8} xs={12} >
                {/* <Grid container mt={{ xs: 0, md: 0 }} columnSpacing={1}> */}
                {!UserType.includes("DOCTOR") && !UserType.includes("SCAN") ? (
                    <Grid
                      container
                      // mt={{
                      //   xs: CurrentPath.pathname.includes("Profile") ? 2.1 : 3,
                      //   md: 5,
                      // }}
                      columnSpacing={1}
                    >
                      <Grid item xs={12} md={5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "37px",
                          }}
                        >
                          <Typography
                            htmlFor="selectDoctor"
                            sx={{
                              fontWeight: "400",
                              color: "#000000",
                              fontSize: "14px",
                            }}
                          >
                            Doctor {RemoveManDatoryDoc ? "*" : ""}{" "}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                            {UserTypeAccess == "SUPER_ADMIN" && CurrentPath.pathname.includes("/Profile") ? <BasicSelect name="user_uid" 
                          readOnly={isEdit}
                          value={formData.user_uid || "default"} 
                          onChange={onChangeValue} >
                      <MenuItem value="default" style={{ display: "none" }}>
                      <span style={{ color: "#bbb7b7" }}>Select</span>
                      </MenuItem>
                      {Array.isArray(doctor) && doctor.length > 0 ? doctor.map((val, i) => (
                    <BasicMenuItem key={i} value={val.user_uid}>
                      {val.full_name}
                    </BasicMenuItem>
                  )) : <BasicMenuItem disabled>
                      No Doctors Available
                    </BasicMenuItem>}</BasicSelect> : 
                <MultipleSelect
                  sx={{  }}
                  value={formData?.multiple_user_uid.length > 0 ? formData?.multiple_user_uid : ["Select"]}
                  name="doctor"
                  readOnly={isEdit}
                  error={!!errors.doctor}
                  onChange={onChangeDoctor}
                  disabled={UserTypeAccess == "SUPER_ADMIN" ? false : true}
                  // disabled={
                  //   // UserType == "NURSE"
                  // }
                  typeOfValue={formData?.multiple_user_uid.length > 0 && "OBJECT"}
                  typeOfKey="full_name"
                >
                  <MenuItem value="Select" style={{ display: "none" }}>
                    Select
                  </MenuItem>
                  {Array.isArray(doctor) && doctor.length > 0 ? doctor.map((val, i) => (
                    <BasicMenuItem key={i} value={val}>
                      <ListItemText
                        primary={val.full_name}
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontSize: { sm: "14px", xs: "12px" },
                          },
                        }}
                      />
                      <Checkbox
                        sx={{ padding: 0, marginLeft: "10px", '&.Mui-checked': { color: "#243665", }, }}
                        checked={formData?.multiple_user_uid.some((mapedVal) => val.user_uid == mapedVal.user_uid)}
                        size="small"
                      />
                    </BasicMenuItem>
                  )) : <BasicMenuItem disabled>
                      No Doctors Available
                    </BasicMenuItem>}
                </MultipleSelect>}

                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      // mt={{
                      //   xs: CurrentPath.pathname.includes("Profile") ? 2.1 : 3,
                      //   md: 5,
                      // }}
                      columnSpacing={1}
                    >
                      <Grid item xs={12} md={5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "37px",
                          }}
                        >
                          <Typography
                            htmlFor="selectDoctor"
                            sx={{
                              fontWeight: "400",
                              color: "#000000",
                              fontSize: "14px",
                            }}
                          >
                            Department&nbsp;*
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="selectDoctor"
                          // disabled = {UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? false : true}
                          required
                          name="department"
                          readOnly={isEdit} 
                          value={formData.department || "default"}
                          error={!!errors.department}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              department: e.target.value,
                            }));
                          }}
                          sx={{ width: "100%", height: "37px", fontSize: "14px" }}
                        >
                          <MenuItem
                            value="default"
                            disabled
                            style={{ display: "none" }}
                          >
                            <span style={{ color: "#bbb7b7" }}> Select</span>
                          </MenuItem>
                          {/* <MenuItem value={"select doctor"} >{"select doctor" }</MenuItem> */}
                          {department?.map((val, index) => (
                            //
                            <MenuItem key={index} value={val}>
                              {val}
                            </MenuItem>
                          ))}
                          {/* <MenuItem value='Kim'>Kim</MenuItem> */}
                        </Select>
                      </Grid>
                    </Grid>
                  )}
              {/* </Grid> */}
              </Grid>
          </Grid>
          {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
              <Grid container gap={1} mt={{ xs: 2, md: 5 }} >
              <Grid item md={5.8} xs={12} >
                {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12} md={5}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "37px",
                        }}
                      >
                        <Typography
                          htmlFor="user_email_id"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            // marginTop:CurrentPath.pathname.includes("manageusers") ? "0px" : "-0.7px",
                          }}
                        >
                          Email Address&nbsp;
                          {UserType === "SUPER_ADMIN" ? "*" : ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        autoComplete="new-password"
                        id="user_email_id"
                        placeholder="Enter email address"
                        error={!!errors.email}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          readOnly: isEdit,
                        }}
                        sx={{
                          width: "100%",
                          height: "30px",
                          // marginTop:CurrentPath.pathname.includes("manageusers") ? "-4px !important" : "2px !important"
                        }}
                        inputProps={{ style: { fontSize: 14 } }}
                        name="email"
                        value={formData.email}
                        onChange={onChangeValue}
                        helperText={
                          <FormHelperText
                            sx={{
                              fontSize: "12px",
                              marginLeft: "-13px",
                              marginTop: "-5px",
                            }}
                          >
                            {errors.email}
                          </FormHelperText>
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item md={5.8} xs={12} >
                {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12} md={5}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "37px",
                        }}
                      >
                        <Typography
                          htmlFor="city_name"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                          }}
                        >
                          City&nbsp;{UserType === "SUPER_ADMIN" ? "*" : ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      {!(UserType == "SUPER_ADMIN") ? (
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="city_name"
                          displayEmpty
                          value={formData.city_uid}
                          error={!!errors.city_name}
                          readOnly={isEdit}
                          name="city_name"
                          onChange={onChangeCity}
                          sx={{ width: "100%", height: "37px", fontSize: "14px" }}
                        >
                          <MenuItem value="" disabled>
                            <span style={{ color: "#bbb7b7" }}>
                              Select the city
                            </span>
                          </MenuItem>
                          {city?.map((cityData, index) => (
                            <MenuItem key={index} value={cityData.city_uid}>
                              {cityData.city_name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField
                          autoComplete="off"
                          id="city_name"
                          placeholder="Enter city"
                          error={!!errors.city_name}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            readOnly: isEdit,
                          }}
                          sx={{
                            width: "100%",
                            height: "30px",
                            marginTop: "-1px !important",
                          }}
                          inputProps={{ style: { fontSize: 14 } }}
                          name="city_name"
                          value={formData.city}
                          onChange={onChangeCity}
                          // helperText={errors.city_name}
                        />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              </Grid>
              ) :
           ("")}
          {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
          <Grid container gap={1} mt={{ xs: 2, md: 5 }} >
          <Grid item md={5.8} xs={12} >
            {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
              <Grid container columnSpacing={1}>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "37px",
                    }}
                  >
                    <Typography
                      htmlFor="stateName"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      State
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="stateName"
                    name="state_name"
                    displayEmpty
                    value={formData.state_uid}
                    error={!!errors.state_name}
                    readOnly={isEdit}
                    onChange={onChangeState}
                    sx={{ width: "100%", height: "37px", fontSize: "14px" }}
                  >
                    <MenuItem value="" disabled style={{ display: "none" }}>
                      <span style={{ color: "#bbb7b7" }}>Select</span>
                    </MenuItem>
                    {/* <MenuItem value={'default'} disabled>Select State</MenuItem> */}
                    {state?.map((stateData, index) => (
                      <MenuItem key={index} value={stateData.state_uid}>
                        {stateData.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid item md={5.8} xs={12} >
            {IsProfilePage === true && UserTypeAccess === "SUPER_ADMIN" ? (
              <Grid container columnSpacing={1}>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "37px",
                    }}
                  >
                    <FormLabel
                      htmlFor="address"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      Address&nbsp;{UserType === "SUPER_ADMIN" ? "*" : ""}
                    </FormLabel>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    id="address"
                    error={!!errors.address}
                    variant="outlined"
                    placeholder="Enter your address"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "30px",
                      // paddingTop: "-7px !important",
                    }}
                    name="address"
                    InputProps={{
                      readOnly: isEdit,
                    }}
                    inputProps={{ maxLength: 250, style: { fontSize: 14 } }}
                    value={formData.address}
                    onChange={onChangeValue}
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
      </Grid>
          ):("")}

          <Grid container gap={1} mt={{ xs: 2, md: 5 }} >
              <Grid item md={5.8} xs={12} >
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "37px",
                      }}
                    >
                      <Typography
                        htmlFor="user_name"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          whiteSpace: "normal",
                        }}
                      >
                        Username&nbsp;*
                        <Tooltip title="Username should be min 8 and max 20 characters.">
                          <IconButton>
                            <InfoOutlinedIcon sx={{ width: "17px" }} />
                          </IconButton>{" "}
                        </Tooltip>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      autoComplete="off"
                      type="text"
                      id="user_name"
                      // disabled={
                      //   UserTypeAccess == "SUPER_ADMIN" ||
                      //   UserTypeAccess == "ADMIN"
                      //     ? false
                      //     : true
                      // }
                      placeholder="Enter your Username"
                      error={!!errors.user_name}
                      required
                      fullWidth
                      InputProps={{
                        readOnly: isEdit,
                        endAdornment: formData.user_name && !isEdit && (
                          <InputAdornment>
                            <CancelIcon
                              onClick={() =>
                                setFormData({ ...formData, user_name: "" })
                              }
                              sx={{ cursor: "pointer" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: "100%",
                        height: "30px",
                      }}
                      className="searchinput"
                      name="user_name"
                      value={formData.user_name}
                      inputProps={{ maxLength: 20, style: { fontSize: 14 } }}
                      onChange={onChangeValue}
                      helperText={
                        <FormHelperText
                          sx={{
                            fontSize: "12px",
                            marginLeft: "-13px",
                            marginTop: "-5px",
                          }}
                        >
                          {errors.user_name}
                        </FormHelperText>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5.8} xs={12} >
                {CurrentPath.pathname.includes("Profile") ? (
    ""
                  ) : (
                    <Grid container columnSpacing={1}>
                      <Grid item xs={12} md={5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "37px",
                          }}
                        >
                          <Typography
                            htmlFor="password"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#000000",
                            }}
                          >
                            Password&nbsp;*
                            <Tooltip title="Password must have minimum 8 characters and maximum 12. Include at least 1 lowercase, 1 upper case, 1 numeric & 1 special character">
                              <IconButton>
                                <InfoOutlinedIcon sx={{ width: "17px" }} />
                              </IconButton>{" "}
                            </Tooltip>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="passwordAuto"
                          fullWidth
                          required
                          placeholder="Enter your Password"
                          error={!!errors.password}
                          InputProps={{
                            readOnly: isEdit,
                            // autocomplete: 'off',
                            form: {
                              autocomplete: "off",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            autocomplete: "new-password",
                            style: { fontSize: 14 },
                          }}
                          variant="outlined"
                          size="small"
                          sx={{ width: "100%", height: "30px" }}
                          name="password"
                          value={formData.password}
                          onChange={onChangeValue}
                          helperText={
                            <FormHelperText
                              sx={{
                                fontSize: "12px",
                                marginLeft: "-13px",
                                marginTop: "-5px",
                              }}
                            >
                              {errors.password ? errors.password : ""}
                            </FormHelperText>
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
              </Grid>
          </Grid>
          {!UserTypeAccess.includes("SUPER_ADMIN") && (CurrentPath.pathname.includes("Profile") || CurrentPath.pathname.includes("Viewusers")) ? "" :  <>
          <Box mt={4}>
            <Typography sx={{ fontSize: "23px", fontWeight: "400" }}>
              Access Permissions
            </Typography>
          </Box>
          <Grid
            item
            sx={{
              marginTop: { sx: "-40px", xs: "0px", md: "8px" },
              marginBottom: "10px",
            }}
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            // className={CurrentPath.pathname.includes("Profile") ? "topThePageAccessPermissions" : "topThePageAccessPermissions"}
          >
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="dashboard"
                control={
                  <Checkbox
                    value="DASHBOARD"
                    disabled={UserTypeAccess == "SUPER_ADMIN" ? false : true}
                    sx={{'&.Mui-checked': { color: "#243665", },}}
                    // disabled={CurrentPath.pathname.includes("Profile") && UserTypeAccess === "SUPER_ADMIN"}
                    inputProps={{
                      readOnly:
                        CurrentPath.pathname.includes("Profile") &&
                        UserTypeAccess === "SUPER_ADMIN"
                          ? true
                          : isEdit,
                    }}
                    // name="access_permissions"
                    checked={Access.includes("DASHBOARD") ? true : false}
                    onChange={changeAccessLevel}
                  />
                }
                label={
                  <Typography
                    sx={{ fontWeight: 400, color: "#000000", fontSize: "14px", cursor: "default" }}
                  >
                    Dashboard
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="manageappointments"
                // disabled={isEdit}
                control={
                  <Checkbox sx={{'&.Mui-checked': { color: "#243665", },}}
                    disabled={UserTypeAccess == "SUPER_ADMIN" ? false : true}
                    value="MANAGE_APPOINTMENTS"
                    checked={
                      Access.includes("MANAGE_APPOINTMENTS") ? true : false
                    }
                    onChange={changeAccessLevel}
                  />
                }
                label={
                  <Typography
                    sx={{ fontWeight: 400, color: "#000000", fontSize: "14px", cursor: "default" }}
                  >
                    Manage Appointments
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="manageusers"
                // disabled={isEdit}
                control={
                  <Checkbox sx={{'&.Mui-checked': { color: "#243665", },}}
                    disabled={UserTypeAccess == "SUPER_ADMIN" ? false : true}
                    value="MANAGE_USERS"
                    checked={Access.includes("MANAGE_USERS") ? true : false}
                    onChange={changeAccessLevel}
                  />
                }
                label={
                  <Typography
                    sx={{ fontWeight: 400, color: "#000000", fontSize: "14px", cursor: "default" }}
                  >
                    Manage Users
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="settings"
                // disabled={isEdit}
                control={
                  <Checkbox sx={{'&.Mui-checked': { color: "#243665", },}}
                    disabled={UserTypeAccess == "SUPER_ADMIN" ? false : true}
                    value="SETTINGS"
                    checked={Access.includes("SETTINGS") ? true : false}
                    onChange={changeAccessLevel}
                  />
                }
                label={
                  <Typography
                    sx={{ fontWeight: 400, color: "#000000", fontSize: "14px", cursor: "default" }}
                  >
                    Settings
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="reports"
                // disabled={isEdit}
                control={
                  <Checkbox sx={{'&.Mui-checked': { color: "#243665", },}}
                    disabled={UserTypeAccess == "SUPER_ADMIN" ? false : true}
                    value="REPORTS"
                    checked={Access.includes("REPORTS") ? true : false}
                    onChange={changeAccessLevel}
                  />
                }
                label={
                  <Typography
                    sx={{ fontWeight: 400, color: "#000000", fontSize: "14px", cursor: "default" }}
                  >
                    Reports
                  </Typography>
                }
                labelPlacement="end"
              />
            </FormGroup>
            {errors.accessError ? (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                choose Atleast any one of the Access Permission
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          </> }

          {!isEdit &&
          (CurrentPath.pathname.includes("Profile") ||
            CurrentPath.pathname.includes("Viewusers")) ? (
            <>
              <Box
                display={"flex"}
                sx={{ marginBottom: { xs: "10px", sm: "10px", md: "0px" }, marginTop:"50px"}}
                alignItems={"center"}
                // className="topThePageAccessPermissionsButtons"
              >
                <Button
                  type="button"
                  sx={{
                    // mt: 3,
                    // mb: 2,
                    mr: 2,
                    backgroundColor: "#D13C3C",
                    color: "#FFFFFF",
                    border: "1px solid #FFF",
                    borderRadius: "50px",
                    height: "40px",
                    "&:hover": { background: "#D13C3C" },
                    width: "154px",
                  }}
                  onClick={() => openPopup("Cancel")}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    // mt: 3,
                    // mb: 2,
                    backgroundColor: "#41BA8F",
                    color: "#FFFFFF",
                    border: "1px solid #FFF",
                    borderRadius: "50px",
                    height: "40px",
                    "&:hover": { background: "#41BA8F" },
                    width: "154px",
                  }}
                >
                  {CurrentPath.pathname.includes("addusers")
                    ? "Confirm"
                    : "update"}
                </Button>
              </Box>
            </>
          ) : (
            ""
          )}

          {CurrentPath.pathname.includes("Editusers") ||
          CurrentPath.pathname.includes("addusers") ? (
            <>
              <Box
                display={"flex"}
                alignItems={"center"}
                //  className="topThePageAccessPermissionsButtons"
                sx={{ marginBottom: { xs: "10px", sm: "10px", md: "0px" } }}
              >
                <Button
                  type="button"
                  sx={{
                    // mt: 3,
                    // mb: 2,
                    mr: 2,
                    backgroundColor: "#D13C3C",
                    color: "#FFFFFF",
                    border: "1px solid #FFF",
                    borderRadius: "50px",
                    height: "40px",
                    "&:hover": { background: "#D13C3C" },
                    width: "154px",
                  }}
                  // onClick={cancelUser}
                  onClick={() => openPopup("Cancel")}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    // mt: 3,
                    // mb: 2,
                    backgroundColor: "#41BA8F",
                    color: "#FFFFFF",
                    border: "1px solid #FFF",
                    borderRadius: "50px",
                    height: "40px",
                    "&:hover": { background: "#41BA8F" },
                    width: "154px",
                  }}
                >
                  {CurrentPath.pathname.includes("addusers")
                    ? "Confirm"
                    : "update"}
                </Button>
              </Box>
            </>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        open={open}
        onClose={PopUpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmationPop
          Data={popupData}
          yesSubmit={yesSubmit}
          NoSubmit={() => setOpen(false)}
        />
      </Modal>
    </div>
  );
}
